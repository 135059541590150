/************************************************

	メンテナンスページ

*************************************************/
.maintenance {
	.maintenance_content {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
		
		.maintenance_content__box {
			
			.maintenance_content__img {
				text-align: center;
			}
			
			.maintenance_content__ttl {
				font-size: 3rem;
				text-align: center;
				line-height: 1;
				margin-top: 25px;
				
				@include mq() {
					margin-top: 15px;
				}
				
			}
			
			.maintenance_content__txt {
				text-align: center;
				line-height: 1.75em;
				margin-top: 35px;
				
				@include mq() {
					margin-top: 25px;
				}
				
			}
			
		}
	}
}