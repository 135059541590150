/************************************************

	社会貢献活動詳細

*************************************************/
.csr_detail {
	
	.csr_detail_txt_wrap {
		padding: 0 5px;
	}
	
	.csr_detail_date {
		margin-bottom: 20px;
		
		dl {
			font-size: 1.6rem;
			display: flex;
			
			
			dt {
				margin-right: 10px;
			}
			
		}
		
		.ico_csr_new {
			margin-left: 10px;
			
			span {
				background: #E4011E;
				padding: 5px;
				color: #fff;
				/*font-size: 1.3rem;*/
			}
		}
		
		
	}
	
	.csr_detail_ico {
		margin-bottom: 30px;
		
		ul {
			display: flex;
			flex-wrap: wrap;
			
			li {
				margin-right: 2px;
				margin-bottom: 2px;
				
				&:last-child {
					margin-right: 0;
					margin-bottom: 0;
				}
				
				@include mq(414) {
					width: 18.8%;
					margin-right: calc(7.5% / 5);
					margin-bottom: 1.5%;
					
					&:nth-child(5n) {
						margin-right: 0;
					}
					
				}
				
				
			}
			
		}
	}
	
	.csr_detail_tit {
		font-size: 2.5rem;
		margin-bottom: 25px;
		color: #4d4d4d;
	}
	
	.csr_detail_txt {
		margin-bottom: 15px;
		
		&:last-child {
			margin-bottom: 0;
		}
		
	}
	
	.csr_detail_pic_wrap {
		display: flex;
		flex-wrap: wrap;
		margin-top: 30px;
		
		@include mq() {
			display: block;
		}
		
		.csr_detail_pic {
			margin-right: calc(8% / 2);
			margin-bottom: 50px;
			max-width: 570px;
			width: 48%;
			
			&:nth-child(2n) {
				margin-right: 0;
			}
			
			&:last-child {
				margin-bottom: 0;
			}
			
			
			@include mq() {
				max-width: inherit;
				width: 100%;
				margin-bottom: 25px;
			}
			
			.csr_detail_pic_cap {
				margin-top: 5px;
				font-size: 1.5rem;
			}
			
		}
		
		.photo_modal {
			cursor: pointer;
		}
		
	}
	
	.csr_detail_btn_wrap {
		margin-top: 30px;
		
		.csr_detail_pdf {
			max-width: 275px;
			text-align: center;
			background: #065c97;
			color: #fff;
			border-radius: 8px;
			font-size: 2.3rem;
			margin: 0 auto;
			transition: .3s;
			
			&:hover {
					opacity: .7;
				}
			
			@include mq() {
				max-width: 80%;
				font-size: 2.1rem;
			}
			
			a {
				display: block;
				padding: 10px;
				position: relative;
				
				&::after {
					content: "";
					position: absolute;
					background: url("../images/ico_pdf01.png") no-repeat left center/28px;
					width: 28px;
					padding-bottom: 37px;
					right: 15px;
				}
				
			}
			
		}
		
	}
	
	
}

