/************************************************

	製品案内

*************************************************/
.product_top {
	
	.product_top_txt01 {
		text-align: center;
		color: #4d4d4d;
		margin-bottom: 100px;

		@include mq(1023) {
			margin: 0 10px 50px;
		}

		h3 {
			font-size: 4.2rem;
			letter-spacing: 0.3rem;
			margin-bottom: 30px;

			@include mq(1023) {
				font-size: 2.2rem;
				margin-bottom: 20px;
			}

			@include mq(320) {
				font-size: 2.1rem;
			}

		}

		p {
			font-size: 2.5rem;
			font-weight: normal;

			@include mq(1023) {
				font-size: 1.8rem;
			}

			@include mq(320) {
				font-size: 1.7rem;
			}

		}

	}
	
	.product_anime_wrap {
		padding: 0 10px;
		
	}
	
	.product_anime {
		max-width: 1200px;
		position: relative;
		overflow: hidden;
		margin: 0 auto 40px;
        
        .product_anime_cover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid $blue01;
            box-sizing: border-box;
            background: #fff;
            display: -webkit-flex;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: opacity .3s;
            
            &.is-open {
                opacity: 0;
                pointer-events: none;
            }
        }
        
        svg {

            .name {
                opacity: 0;
                transition: opacity .3s;
                position: relative;
                pointer-events: none;
            }

            .marker {
                opacity: 1;
                transition: opacity .3s;
                cursor: pointer;
                pointer-events: auto;
            }
            
            &.is-hover {
                
                .name {
                    opacity: 1;
                }

                .marker {
                    opacity: 0;
                }
            }
        }
        
        .btn_detail_link {
            cursor: pointer;
        }
        
        .product_anime_links {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            
            .btn_detail_link {
                pointer-events: auto;
                position: absolute;
                //background: url('/product/animation/ui/img_pin.png') no-repeat center top / 100% auto;
                
                a {
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    //background: red;
                    //opacity: .5;
                }
                
                a::before {
                    content: '';
                    display: block;
                    background: url('/product/animation/ui/img_pin.png') no-repeat center top / 100% auto;
                    position: absolute;
                    transform: translate(-50%, -100%);
                    //pointer-events: none;
                    transition: opacity .3s;
                }
                
                a::after {
                    content: '';
                    display: block;
                    position: absolute;
                    transform: translate(-50%, -100%);
                    opacity: 0;
                    transition: opacity .3s;
                    pointer-events: none;
                }
                
                a:hover {

                    &::before {
                        opacity: .5;
                    }

                    &::after {
                        opacity: 1;
                    }
                }
                
                &.btn_detail_link_03 {
                    top: 67%;
                    left: 57.7%;
                    width: 2.25%;
                    padding-bottom: 8.8%;
                    
                    a::before {
                        top: 0;
                        left: 50%;
                        width: 57%;
                        padding-bottom: 89%;
                    }
                    
                    a::after {
                        background: url('/product/animation/ui/name_product03.png') no-repeat center top / 100% auto;
                        top: 11%;
                        left: 50%;
                        width: 347%;
                        padding-bottom: 139%;
                    }
                }
                
                /*&.btn_detail_link_04 {
                    top: 22.8%;
                    left: 49.1%;
                    width: 2.25%;
                    padding-bottom: 4.3%;
                    
                    a::before {
                        top: 0;
                        left: 50%;
                        width: 57%;
                        padding-bottom: 89%;
                    }
                    
                    a::after {
                        background: url('/product/animation/ui/name_product04.png') no-repeat center top / 100% auto;
                        top: 11%;
                        left: 169%;
                        width: 717%;
                        padding-bottom: 217%;
                    }
                }*/
                
                &.btn_detail_link_04 {
                    top: 14.8%;
                    left: 48.9%;
                    width: 2.25%;
                    padding-bottom: 8.4%;
                    
                    &.is-opened {
                        
                        a::before {
                            background-image: url('/product/animation/ui/img_pin_minus.png');
                        }
                    }
                    
                    a::before {
                        background: url('/product/animation/ui/img_pin_plus.png') no-repeat center top / 100% auto;
                        top: 0;
                        left: 0;
                        width: 100%;
                        padding-bottom: 142%;
                        transform: none;
                        pointer-events: none;
                    }
                    
                    a::after {
                        content: none;
                    }
                    
                    a:hover {
                        
                        &::before {
                            opacity: .7;
                        }
                    }
                }
                
                &.btn_detail_link_05 {
                    top: 8.8%;
                    left: 17.1%;
                    width: 2.25%;
                    padding-bottom: 3.4%;
                    transform: rotate(90deg);
                    
                    &.is-opened {
                        
                        a::before {
                            background-image: url('/product/animation/ui/img_pin_minus.png');
                        }
                    }
                    
                    a::before {
                        background: url('/product/animation/ui/img_pin_plus.png') no-repeat center top / 100% auto;
                        top: 0;
                        left: 0;
                        width: 100%;
                        padding-bottom: 142%;
                        transform: none;
                        pointer-events: none;
                    }
                    
                    a::after {
                        content: none;
                    }
                    
                    a:hover {
                        
                        &::before {
                            opacity: .7;
                        }
                    }
                }
                
                &.btn_detail_link_09 {
                    top: 61.3%;
                    left: 85.1%;
                    width: 10.55%;
                    padding-bottom: 2.9%;
                    
                    a::before {
                        top: 0;
                        left: 27%;
                        width: 13%;
                        padding-bottom: 21%;
                    }
                    
                    a::after {
                        background: url('/product/animation/ui/name_product09.png') no-repeat center top / 100% auto;
                        top: 14%;
                        left: 57%;
                        width: 97%;
                        padding-bottom: 32%;
                    }
                }
                
                &.btn_detail_link_10 {
                    top: 76.3%;
                    left: 62.1%;
                    width: 3.55%;
                    padding-bottom: 6.9%;
                    
                    a::before {
                        top: 0;
                        left: 50%;
                        width: 38%;
                        padding-bottom: 59%;
                    }
                    
                    a::after {
                        background: url('/product/animation/ui/name_product10.png') no-repeat center top / 100% auto;
                        top: 7%;
                        left: 29%;
                        width: 367%;
                        padding-bottom: 82%;
                    }
                }
                
                &.btn_detail_link_11 {
                    top: 7.3%;
                    left: 35.7%;
                    width: 5.15%;
                    padding-bottom: 2.6%;
                    
                    a::before {
                        top: 0;
                        left: 50%;
                        width: 22.9%;
                        padding-bottom: 38%;
                    }
                    
                    a::after {
                        background: url('/product/animation/ui/name_product11.png') no-repeat center top / 100% auto;
                        top: 22%;
                        left: 113%;
                        width: 207%;
                        padding-bottom: 64%;
                    }
                }
                
                &.btn_detail_link_12 {
                    top: 51.3%;
                    left: 10.7%;
                    width: 7.15%;
                    padding-bottom: 6.6%;
                    
                    a::before {
                        top: 0;
                        left: 59%;
                        width: 17%;
                        padding-bottom: 27%;
                    }
                    
                    a::after {
                        background: url('/product/animation/ui/name_product12.png') no-repeat center top / 100% auto;
                        top: 0%;
                        left: 136%;
                        width: 158%;
                        padding-bottom: 100%;
                    }
                }
                
                &.btn_detail_link_13 {
                    top: 49%;
                    left: 78.9%;
                    width: 3.95%;
                    padding-bottom: 3.4%;
                    
                    a::before {
                        top: 0;
                        left: 50%;
                        width: 32%;
                        padding-bottom: 50%;
                    }
                    
                    a::after {
                        background: url('/product/animation/ui/name_product13.png') no-repeat center top / 100% auto;
                        top: 10%;
                        left: 69%;
                        width: 267%;
                        padding-bottom: 74%;
                    }
                }
                
                &.btn_detail_link_14 {
                    top: 35%;
                    left: 90.2%;
                    width: 6.85%;
                    padding-bottom: 3.9%;
                    
                    a::before {
                        top: 0;
                        left: 50%;
                        width: 17%;
                        padding-bottom: 26%;
                    }
                    
                    a::after {
                        background: url('/product/animation/ui/name_product14.png') no-repeat center top / 100% auto;
                        top: 168%;
                        left: 22%;
                        width: 120%;
                        padding-bottom: 45%;
                    }
                }
                
                &.btn_detail_link_16 {
                    top: 63.5%;
                    left: 9.1%;
                    width: 4.45%;
                    padding-bottom: 6.3%;
                    
                    a::before {
                        top: 0;
                        left: 50%;
                        width: 29%;
                        padding-bottom: 44%;
                    }
                    
                    a::after {
                        background: url('/product/animation/ui/name_product16.png') no-repeat center top / 100% auto;
                        top: 214%;
                        left: 82%;
                        width: 292%;
                        padding-bottom: 157%;
                    }
                }
                
                &.btn_detail_link_17 {
                    top: 6.5%;
                    left: 32%;
                    width: 3.05%;
                    padding-bottom: 3.1%;
                    
                    a::before {
                        top: 0;
                        left: 50%;
                        width: 39%;
                        padding-bottom: 62%;
                    }
                    
                    a::after {
                        background: url('/product/animation/ui/name_product17.png') no-repeat center top / 100% auto;
                        top: 204%;
                        left: 122%;
                        width: 622%;
                        padding-bottom: 107%;
                    }
                }
                
                &.btn_detail_link_18 {
                    top: 33.5%;
                    left: 60.3%;
                    width: 4.35%;
                    padding-bottom: 4.3%;
                    
                    a::before {
                        top: 0;
                        left: 63%;
                        width: 29%;
                        padding-bottom: 45%;
                    }
                    
                    a::after {
                        background: url('/product/animation/ui/name_product18.png') no-repeat center top / 100% auto;
                        top: 5%;
                        left: 36%;
                        width: 300%;
                        padding-bottom: 63%;
                    }
                }
                
                &.btn_detail_link_19 {
                    top: 55.5%;
                    left: 60.3%;
                    width: 4.35%;
                    padding-bottom: 3.9%;
                    
                    a::before {
                        top: 0;
                        left: 50%;
                        width: 29%;
                        padding-bottom: 45%;
                    }
                    
                    a::after {
                        background: url('/product/animation/ui/name_product19.png') no-repeat center top / 100% auto;
                        top: 0%;
                        left: 48%;
                        width: 243%;
                        padding-bottom: 76%;
                    }
                }
                
                &.btn_detail_link_20 {
                    top: 35%;
                    left: 73.8%;
                    width: 4.35%;
                    padding-bottom: 3.9%;
                    
                    a::before {
                        top: 0;
                        left: 50%;
                        width: 29%;
                        padding-bottom: 45%;
                    }
                    
                    a::after {
                        background: url('/product/animation/ui/name_product20.png') no-repeat center top / 100% auto;
                        top: 0%;
                        left: 108%;
                        width: 189%;
                        padding-bottom: 71%;
                    }
                }
                
                &.btn_detail_link_21 {
                    top: 25%;
                    left: 78.6%;
                    width: 2.85%;
                    padding-bottom: 3.6%;
                    
                    a::before {
                        top: 0;
                        left: 50%;
                        width: 39%;
                        padding-bottom: 60%;
                    }
                    
                    a::after {
                        background: url('/product/animation/ui/name_product21.png') no-repeat center top / 100% auto;
                        top: 12%;
                        left: 135%;
                        width: 289%;
                        padding-bottom: 103%;
                    }
                }
                
                &.btn_detail_link_22 {
                    top: 71%;
                    left: 15.6%;
                    width: 6.85%;
                    padding-bottom: 7.2%;
                    
                    a::before {
                        top: 0;
                        left: 50%;
                        width: 18%;
                        padding-bottom: 28%;
                    }
                    
                    a::after {
                        background: url('/product/animation/ui/name_product22.png') no-repeat center top / 100% auto;
                        top: 11%;
                        left: 90%;
                        width: 187%;
                        padding-bottom: 47%;
                    }
                }
            }
            
            .products_popup {
                padding: 0.55%;
                background: #fff;
                display: -webkit-flex;
                display: flex;
                flex-wrap: wrap;
                position: absolute;
                top: 1%;
                left: 20.7%;
                width: 28%;
                pointer-events: auto;
                box-shadow: 3px 3px 12px 0 rgba(0, 0, 0 ,.4);
                opacity: 0;
                pointer-events: none;
                transition: opacity .3s;
                
                &.is-show {
                    opacity: 1;
                    pointer-events: auto;
                }
                
                li {
                    width: 49%;
                    
                    &:nth-child(odd) {
                        margin-right: 2%;
                    }
                    
                    &:nth-child(n+3) {
                        margin-top: 2%;
                    }
                    
                    a {
                        transition: opacity .3s;
                        
                        &:hover {
                            opacity: .7;
                        }
                    }
                }
            }
            
            .products_popup02 {
                padding: 0.55%;
                background: #fff;
                display: -webkit-flex;
                display: flex;
                flex-wrap: wrap;
                position: absolute;
                top: 1%;
                left: 42.5%;
                width: 14%;
                pointer-events: auto;
                box-shadow: 3px 3px 12px 0 rgba(0, 0, 0 ,.4);
                opacity: 0;
                pointer-events: none;
                transition: opacity .3s;
                
                &.is-show {
                    opacity: 1;
                    pointer-events: auto;
                }
                
                li {
                    width: 100%;
                    
                    &:nth-child(odd) {
                        //margin-right: 2%;
                    }
                    
                    &:nth-child(n+3) {
                        //margin-top: 2%;
                    }
                    
                    a {
                        transition: opacity .3s;
                        
                        &:hover {
                            opacity: .7;
                        }
                    }
                }
            }
        }
	}

	.bg {
		/*position: absolute;*/
		top: 0;
		left: 0;
	}

	#rale,
	#fence,
	#lottie,
	#lottie2,
	#lottie3,
	#lottie4,
	#lottie5,
	#lottie6,
	#lottie7,
	#lottie8,
	#lottie9,
	#lottie10,
	#lottie11,
	#lottie12,
	#lottie13{
		position: absolute;
		width: 100%;
		height: 46.5vw;
		top: 0;
		left: 0;
		/*pointer-events: none;*/
	}
	.btn_detail {
		/*pointer-events: auto !important;*/
	}
	#rale {
		width: 58%;
		height: auto;
		top: 33.6%;
		left: 0%;
		pointer-events: none;
	}
	#fence {
		width: 29.2%;
		height: auto;
		top: 56.9%;
		left: 0%;
		pointer-events: none;
	}
	#lottie {
		//pointer-events: none;
		height: 0;
		padding-bottom: 46.5%;
		
		.btn_detail,
		.marker {
			cursor: pointer;
		}
		
		.png,
		.ai {
			
			&:not(.btn_detail):not(.marker) {
				pointer-events: none;
			}
		}
	}

	#lottie svg {
		position: absolute;
	}

	#lottie2 {
		width: 7%;
		height: 17%;
		top: 66%;
		left: 75%;
	}
	#lottie3 {
		width: 10.1%;
		height: 28.3%;
		top: 43.6%;
		left: 2.1%;
	}
	#lottie4 {
		width: 17%;
		height: 11%;
		top: 22%;
		left: 42%;
	}
	#lottie5 {
		width: 28.4%;
		height: 33%;
		top: 36%;
		left: 24%;
		
		pointer-events: none;
		
		.png,
		.ai,
		.btn_detail{
			pointer-events: none;
		}
	}
	#lottie6 {
		width: 19%;
		height: 38%;
		top: 66.8%;
		left: 57.6%;
	}
	#lottie7 {
		width: 47%;
		height: 53%;
		top: 4%;
		left: -16%;
		pointer-events: none;
	}
	#lottie7 .btn_detail {
		pointer-events: auto;
        cursor: pointer;
	}
	#lottie8 {
		width: 26.8%;
		height: 31.2%;
		top: 68.1%;
		left: 77.3%;
	}
	#lottie9 {
		width: 18.2%;
		height: 13%;
		top: 63.9%;
		left: 2.1%;
	}
	#lottie10 {
		width: 3%;
		height: 12%;
		top: 4%;
		left: 32%;
	}
	#lottie11 {
		width: 13%;
		height: 22%;
		top: 80%;
		left: 66%;
	}
	#lottie12 {
		width: 9%;
		height: 16%;
		top: 59.4%;
		left: 14.7%;
	}
	#lottie13 {
		width: 9%;
		height: 16%;
		top: 62.6%;
		left: 18.7%;
	}
	
	
	.product_lineup_wrap {
		max-width: 1200px;
		margin: 0 auto;
		padding: 0 10px;

		.product_lineup_box {
			margin-bottom: 30px;
			
			.product_lineup_tit {
				background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,153,68,1) 36%, rgba(0,153,68,1) 100%);
				background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,153,68,1) 36%, rgba(0,153,68,1) 100%);
				background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(0,153,68,1) 36%, rgba(0,153,68,1) 100%);
				color: #fff;
				padding: 5px 0 5px 10px;
				font-size: 2.5rem;
				margin-bottom: 25px;
				
				&.wood_tit {
					background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(234,85,20,1) 36%, rgba(234,85,20,1) 100%);
					background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(234,85,20,1) 36%, rgba(234,85,20,1) 100%);
					background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(234,85,20,1) 36%, rgba(234,85,20,1) 100%);
				}
				
				&.tool_tit {
					background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(233,83,119,1) 36%, rgba(233,83,119,1) 100%);
					background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(233,83,119,1) 36%, rgba(233,83,119,1) 100%);
					background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(233,83,119,1) 36%, rgba(233,83,119,1) 100%);
				}
				
				&.prevention_tit {
					background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,160,233,1) 36%, rgba(0,160,233,1) 100%);
					background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,160,233,1) 36%, rgba(0,160,233,1) 100%);
					background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(0,160,233,1) 36%, rgba(0,160,233,1) 100%);
				}
				
				@include mq() {
					font-size: 2.1rem;
				}
				
			}
			
			.product_lineup_list {
				display: flex;
				flex-wrap: wrap;
				
				@include mq(320) {
					display: block;
				}
				
			}
			
			&:last-child {
				margin-bottom: 0;
			}
			
		}
	}
	
	
	.pickup_list_item {
		background: #fff;
		box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, .3);
		max-width: 270px;
		width: 23%;
		margin-right: calc(8% / 3);
		margin-bottom: 45px;
		
		&:nth-child(4n) {
			margin-right: 0;
		}
		
		@include mq(1023) {
			max-width: inherit;
			width: 30%;
			margin-right: calc(15% / 3);
			
			&:nth-child(4n) {
				margin-right: calc(15% / 3);
			}
			
			&:nth-child(3n) {
				margin-right: 0;
			}
			
		}
		
		@include mq() {
			width: 48%;
			margin-right: calc(8% / 2);
			
			&:nth-child(4n) {
				margin-right: 0;
			}
			
			&:nth-child(3n) {
				margin-right: calc(8% / 2);
			}
			
			&:nth-child(2n) {
				margin-right: 0;
			}
			
		}
		
		@include mq(320) {
			width: 100%;
		}
		
		
		@include pc_hover {

			.pickup_photo {

				img {
					transform: scale(1.1);
				}
			}

			.pickup_text {

				&::before {
					transform: translate(-5px, -5px);
				}

				&::after {
					transform: translate(5px, -5px);
				}

				> div {

					&::before {
						transform: translate(-5px, 5px);
					}

					&::after {
						transform: translate(5px, 5px);
					}
				}
			}
		}

		.pickup_photo {
			width: 100%;
			height: 0;
			position: relative;
			padding-bottom: 72%;
			overflow: hidden;

			img {
				position: absolute;
				top: 0;
				left: 0;
				transition: transform .5s;
			}
		}

		.pickup_text {
			padding: 15px;
			position: relative;
			box-sizing: border-box;
			font-size: 1.8rem;
			text-align: center;
			line-height: 1.3;

			@include mq() {
				padding: 10px;
			}

			&::before,
			&::after {
				content: '';
				display: block;
				width: 18px;
				height: 18px;
				position: absolute;
				top: 15px;
				transition: transform .5s;

				@include mq() {
					top: 10px;
				}
			}

			&::before {
				border-top: 2px solid $green01;
				border-left: 2px solid $green01;
				left: 15px;

				@include mq() {
					left: 10px;
				}
			}

			&::after {
				border-top: 2px solid $green01;
				border-right: 2px solid $green01;
				right: 15px;

				@include mq() {
					right: 10px;
				}
			}

			> div {
				position: relative;

				&::before,
				&::after {
					content: '';
					display: block;
					width: 18px;
					height: 18px;
					position: absolute;
					bottom: 0;
					transition: transform .5s;
				}

				&::before {
					border-bottom: 2px solid $green01;
					border-left: 2px solid $green01;
					left: 0;
				}

				&::after {
					border-bottom: 2px solid $green01;
					border-right: 2px solid $green01;
					right: 0;
				}

				> p {
					padding: 8px 11px 13px;
					display: -webkit-flex;
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;

					&::after {
						content: '';
						display: block;
						width: 22px;
						height: 22px;
						background: url("../images/ico_arrow_green.svg") no-repeat center / 22px 22px;
						position: absolute;
						bottom: 0%;
						left: 50%;
						transform: translate(-50%, 25px);
					}
				}
			}

			.small {
				font-size: 1.2rem !important;
				line-height: 1.3;
				display: block;
				padding-bottom: 7px;
				font-weight: normal !important;
				
				
				
			}
			
			
			 .small02 {
				font-size: 1.4rem !important;
				line-height: 1.3;
				display: block;
				position: relative;
				margin-top: 7px;
				padding-top: 7px;
				font-weight: normal !important;
				
				&::before {
					content: "";
					background: #000;
					width: 100%;
					padding-bottom: 1px;
					position: absolute;
					top: 0;
					left: 0;
				}
				
				@include mq() {
					font-size: 1.3rem !important;
				}
				
			}
			
			.ico_arrow02 {
				//display: inline-block;
				span {
					width: 100%;
					font-weight: bold;
					
					@include mq() {
						font-size: 1.5rem;
					}
					
				}
				
				
			}
		}

		a {

			&:focus {
				outline: none;
				box-shadow: none;
			}
		}
		
		&.wood_item {
			
			.pickup_text {
				&::before {
					border-top: 2px solid $orange02;
					border-left: 2px solid $orange02;
					left: 15px;

					@include mq() {
						left: 10px;
					}
				}

				&::after {
					border-top: 2px solid $orange02;
					border-right: 2px solid $orange02;
					right: 15px;

					@include mq() {
						right: 10px;
					}
				}

				> div {
					position: relative;

					&::before {
						border-bottom: 2px solid $orange02;
						border-left: 2px solid $orange02;
						left: 0;
					}

					&::after {
						border-bottom: 2px solid $orange02;
						border-right: 2px solid $orange02;
						right: 0;
					}
					
					> p {
						&::after {
							background: url("../images/ico_arrow_orange.svg") no-repeat center / 22px 22px;
						}
					}
					

				}

			}
		}
		
		&.tool_item {
			
			.pickup_text {
				&::before {
					border-top: 2px solid $red02;
					border-left: 2px solid $red02;
					left: 15px;

					@include mq() {
						left: 10px;
					}
				}

				&::after {
					border-top: 2px solid $red02;
					border-right: 2px solid $red02;
					right: 15px;

					@include mq() {
						right: 10px;
					}
				}

				> div {
					position: relative;

					&::before {
						border-bottom: 2px solid $red02;
						border-left: 2px solid $red02;
						left: 0;
					}

					&::after {
						border-bottom: 2px solid $red02;
						border-right: 2px solid $red02;
						right: 0;
					}
					
					> p {
						&::after {
							background: url("../images/ico_arrow_pink.svg") no-repeat center / 22px 22px;
						}
					}
					

				}

			}
		}
		
		&.prevention_item {
			
			.pickup_text {
				&::before {
					border-top: 2px solid $blue03;
					border-left: 2px solid $blue03;
					left: 15px;

					@include mq() {
						left: 10px;
					}
				}

				&::after {
					border-top: 2px solid $blue03;
					border-right: 2px solid $blue03;
					right: 15px;

					@include mq() {
						right: 10px;
					}
				}

				> div {
					position: relative;

					&::before {
						border-bottom: 2px solid $blue03;
						border-left: 2px solid $blue03;
						left: 0;
					}

					&::after {
						border-bottom: 2px solid $blue03;
						border-right: 2px solid $blue03;
						right: 0;
					}
					
					> p {
						&::after {
							background: url("../images/ico_arrow_blue.svg") no-repeat center / 22px 22px;
						}
					}
					

				}

			}
		}
		
	}
	
	
	
	
}

