/************************************************

	会社概要

*************************************************/
.outline_link01 {
	//display: inline-block;
	text-decoration: underline;
	color: $blue01;
	margin-left: 10px;
	
	@include pc_hover {
		color: $blue01;
	}
	&:visited {
		color: $blue01;
	}
}

.outline_box {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 10px 70px;
	
	h3 {
		font-size: 2.5rem;
		border-bottom: 1px solid;
		padding-left: 15px;
		color: #005B97;
		
		@include mq() {
			padding-left: 10px;
			font-size: 2.1rem;
		}
		
	}
	
	.outline_table {
		width: 100%;
		
		tr {
			position: relative;
			background-size: 10px 1px;
			background-image: linear-gradient(to right, #005B97, #005B97 5px, transparent 5px, transparent 10px);
			background-repeat: repeat-x;
			background-position: left bottom;
		}
		
		th, td {
			padding: 15px;
			box-sizing: border-box;
			
			@include mq() {
				display: block;
				padding: 10px;
			}
		}
		
		th {
			font-weight: normal;
			width: 160px;
			text-align: left;
			
			@include mq() {
				width: 100%;
				padding: 10px 10px 0;
				font-weight: bold;
			}
			
		}
		
		.officer_list {
			li {
				span {
					display: inline-block;
					width: 112px;
					margin-right: 16px;
				}
			}
		}
		
		.register_list {
			li {
				text-indent: -0.5em;
				padding-left: 7px;
			}
		}
		
	}
	
	@include mq() {
		padding: 0 10px 35px;
	}
	
}

.org_chart_box {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 10px;
	
	h3 {
		font-size: 2.5rem;
		border-bottom: 1px solid;
		padding-left: 15px;
		color: #005B97;
		margin-bottom: 37px;
		
		@include mq() {
			padding-left: 10px;
			font-size: 2.1rem;
			margin-bottom: 15px;
		}
		
	}
	
	.org_chart_box_wrap {
		width: 100%;
		
		@include mq() {
			overflow-x: scroll;
			
			.org_chart_box_pic {
				min-width: 1200px;
			}
			
		}
		
		
		
	}
	
	
}