/************************************************

	企業情報

*************************************************/
.philosophy_box {
	margin: 0 10px 88px;
	
	@include mq() {
		margin: 0 10px 44px;
	}
	
	.philosophy_tit {
		max-width: 335px;
		margin: 0 auto;
		padding-bottom: 30px;
		
		@include mq() {
			max-width: 50%;
			padding-bottom: 15px;
		}
		
	}
	
	.philosophy_txt {
		text-align: center;
		font-family: 'Noto Serif JP', serif;
		font-size: 2.5rem;
		font-weight: bold;
		margin-bottom: 80px;
		
		@include mq() {
			font-size: 1.8rem;
			margin-bottom: 40px;
		}
		
	}
	
}

.company_menu {
	@include mq(1023) {
		margin: 0 10px;
	}
}

.philosophy_list_box {
	border: 2px solid #005B97;
	//max-width: 1005px;
	max-width: 750px;
	margin: 0 auto;
	padding: 25px 35px;
    box-sizing: border-box;
	
	@include mq() {
		padding: 12px 15px;
	}
	
	h3 {
		color: #005B97;
		font-size: 2.5rem;
		margin-bottom: 15px;
		line-height: 1;
		
		@include mq() {
			font-size: 2.1rem;
			margin-bottom: 5px;
		}
		
	}
	
	ul {
		li {
			font-size: 1.8rem;
			margin-bottom: 10px;
			//text-indent: -1em;
			padding-left: 15px;
			position: relative;
			
			&:last-child {
				margin-bottom: 0;
			}
			
			&::before {
				content: "";
				display: inline-block;
				position: absolute;
				left: 0;
				top: 9px;
				width: 10px;
				height: 10px;
				border-radius: 100%;
				background: #005B97;
			}
			
			@include mq() {
				font-size: 1.6rem;
				//text-indent: -1.8em;
				
				&::before {
					top: 8px;
				}
				
			}
			
			span {
				font-size: 2.6rem;
				font-weight: bold;
				color: #005B97;
				
				@include mq() {
					font-size: 2.1rem;
				}
			}
			
		}
	}
	
}

.corp_txt_cap {
	text-align: right;
	font-size: 1.4rem;
	margin-bottom: 5px;
}


