/************************************************

	新着情報一覧

*************************************************/
.news {
	.news_list_box {
		max-width: 1200px;
		margin: 0 auto;
		padding: 0 10px;
	}
	
	.news_list_tab_box {
		margin-bottom: 25px;
		
		.news_list_tab {
			display: flex;
			justify-content: space-between;
			
			@include mq(569) {
				flex-wrap: wrap;
				justify-content: flex-start;
			}
			
			
			.n_list_tab {
				max-width: 235px;
				width: 100%;
				background: #EBEBEB;
				margin-right: calc(3% / 5);
				margin-bottom: 5px;
				transition: all .3s;
				padding: 15px 20px;
				box-sizing: border-box;
				text-align: center;
				cursor: pointer;
				
				@include mq() {
					padding: 10px;
				}
				
				&:hover {
					background: #00A0E2;
					color: #fff;					
				}
				
				&:last-child {
					margin-right: 0;
				}
				
				&.current {
					background: #00A0E2;
					color: #fff;
										
				}
				
				@include mq(569) {
					font-size: 1.3rem;
					margin-right: calc(3% / 3);
					max-width: inherit;
					width: 32.6%;
					
					&:nth-child(3n) {
						margin-right: 0;
					}
					
				}
				
				
				a {
					display: block;
					padding: 10px 20px;
					text-align: center;
					/*transition: opacity .3s;

					@include pc_hover {
						opacity: .7;
					}*/
					
					@include mq() {
						padding: 10px;
					}
					
				}
				
			}
			
		}
		
	}
	
	.news_list {
		display: none;
		
		&.n_list_active {
			display: block;
		}
		
		
		li {
			border-bottom: 1px solid #888;
			
			&:first-of-type {
				a {
					padding-top: 10px;
				}
			}
			
			
			a {
				display: block;
				padding: 20px 0;
				transition: opacity .3s;

                @include pc_hover {
                    opacity: .7;
                }
				
				@include mq() {
					padding: 10px 0;
				}
				
			}
			
		}
		
		.news_list_block {
			display: -webkit-flex;
			display: flex;
			align-items: flex-start;
			
			@include mq() {
				flex-wrap: wrap;
			}
			
			
			.news_list_date {
				width: 90px;
				flex-basis: 90px;
				flex-shrink: 0;
			}
			
			.news_list_category {
				display: -webkit-flex;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 1.2rem;
				line-height: 1;
				background: #00A0E2;
				color: #fff;
				padding: 5px 10px;
				min-width: 70px;
				box-sizing: border-box;
				margin-top: 2px;
				
				&.category_csr {
					background: #FFA00C;
				}
				
				&.category_product {
					background: #009944;
				}
				
			}
			
			.news_list_ttl {
				margin-left: 15px;
				
				@include mq() {
					margin-left: 0;
					width: 100%;
				}
				
			}
			
			.ico_new {
				margin-left: 20px;
				font-size: 1.2rem;
				font-weight: bold;
				line-height: 1;
				display: inline-block;
				padding: 5px 10px;
				border: 1px solid;
				color: #FF0000;
			}
			
		}
	}
}



