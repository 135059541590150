/************************************************

	社会貢献活動

*************************************************/
.csr_top {
	.csr_sdgs {
		display: flex;
		max-width: 650px;
		margin: 0 auto 40px;
		
		@include mq() {
			display: block;
			padding: 0 10px;
		}
		
		.csr_sdgs_pic {
			max-width: 250px;
			margin-right: 30px;
			
			@include mq() {
				max-width: 50%;
				margin: 0 auto 15px;
			}
			
		}
		
		.csr_sdgs_txt {
			font-size: 2.5rem;
			color: #4d4d4d;
			
			@include mq() {
				font-size: 1.8rem;
				text-align: center;
			}
			
		}
		
	}
	
	.csr_top_txt01 {
		text-align: center;
		color: #4d4d4d;
		margin-bottom: 100px;

		@include mq(1023) {
			margin: 0 10px 50px;
		}

		h3 {
			font-size: 4.2rem;
			letter-spacing: 0.3rem;
			margin-bottom: 30px;

			@include mq(1023) {
				font-size: 2.2rem;
				margin-bottom: 20px;
			}

			@include mq(320) {
				font-size: 2.1rem;
			}

		}

		p {
			font-size: 2.5rem;
			font-weight: normal;

			@include mq(1023) {
				font-size: 1.8rem;
			}

			@include mq(320) {
				font-size: 1.7rem;
			}

		}

	}	
	
}

.csr_active_wrap {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 10px;
}

.csr_list_tit {
	background-image: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #1e9cd7 56%, #0075be 86%, #005b97 100%);
    background-image: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #1e9cd7 56%, #0075be 86%, #005b97 100%);
    background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #1e9cd7 56%, #0075be 86%, #005b97 100%);
	color: #fff;
	padding: 5px 0 5px 10px;
	font-size: 2.5rem;
	margin-bottom: 25px;
	
	@include mq() {
		font-size: 2.1rem;
	}
	
}

.csr_list_wrap {
	margin-bottom: 50px;
	
	&:last-child {
		margin-bottom: 0;
	}
	
}

.csr_list_box_wrap {
	display: flex;
	
	@include mq(1023) {
		flex-wrap: wrap;
	}
	
}

.csr_list_box {
	background: #fff;
    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.3);
    //max-width: 380px;
	width: 33%;
	//margin-right: 30px;
	margin-right: calc(7.5% / 3);
	
	&:last-child {
		margin-right: 0;
	}
	
	@include mq(1023) {
		max-width: inherit;
		width: 48%;
		margin-right: calc(7.5% / 2);
		margin-bottom: 35px;
		
		&:nth-child(2n) {
			margin-right: 0;
		}
		
	}
	
	@include mq() {
		width: 100%;
		margin-right: 0;
		margin-bottom: 25px;
		
		&:last-child {
			margin-bottom: 0;
		}
		
	}
	
	
	a {
		display: block;
		transition: .3s;
		
		&:hover {
			opacity: .7;
		}
		
	}
	
	.csr_list_txt {
		padding: 10px;
	}
	
	.csr_list_date {
		dl {
			font-size: 1.5rem;
			display: flex;
			
			@include mq() {
				flex-wrap: wrap;
			}
			
			
			dt {
				margin-right: 10px;
			}
			
			dd {
				margin-right: 10px;
				
				&:last-child {
					margin-right: 0;
				}
			}
			
		}
		
		.ico_csr_new {
			//margin-left: 10px;
			
			span {
				background: #E4011E;
				padding: 5px;
				color: #fff;
				/*font-size: 1.3rem;*/
			}
		}
		
	}
	
	.csr_list_cap {
		margin-top: 10px;
	}
	
	.csr_list_sdgs {
		margin-top: 25px;
		
		ul {
			display: flex;
			flex-wrap: wrap;
			
			li {
				width: 18.8%;
				margin-right: calc(7.5% / 5);
				margin-bottom: 5px;
				
				&:nth-child(5n) {
					margin-right: 0;
				}
				
				
			}
			
		}
	}
	
	.csr_list_more {
		margin-top: 10px;
		text-align: right;
		
		p {
			font-size: 1.7rem;
			
			&::after {
				content: "";
				display: inline-block;
				background: url("../images/ico_arrow_blue02.svg") no-repeat center/18px;
				width: 18px;
				height: 18px;
				vertical-align: middle;
				margin-left: 5px;
			}
		}
		
	}
	
}

.csr_past_list {
	margin-top: 30px;
	text-align: right;
	
	p {
		font-size: 1.8rem;
		font-weight: bold;
		
		@include mq() {
			font-size: 1.7rem;
		}
		
		a {
			text-decoration: underline;
			
			&::before {
				content: "";
				display: inline-block;
				margin-right: 10px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 8px 0 8px 13px;
				border-color: transparent transparent transparent #005b97;
				transform: translateY(1px);
				transition: .3s all;
				
				@include mq() {
					margin-right: 5px;
					border-width: 8px 0 8px 13px;
				}
				
			}
			
			&:hover {
				text-decoration: none;
				
				@include mq() {
					text-decoration: underline;
				}
				
				&::before {
					margin-right: 5px;
					
					@include mq() {
						margin-right: 10px;
					}
					
				}
			}
			
		}
		
	}
}


/* 過去の実績 */
.csr_p_list_box {
	max-width: 1200px;
    margin: 0 auto;
    padding: 0 10px;	
	
}

.csr_p_list_tab_box {
	margin-bottom: 25px;
	
	.csr_p_list_tab {
		display: flex;
		justify-content: space-between;
		
		@include mq(634) {
			flex-wrap: wrap;
			justify-content: flex-start;
		}
		
		
		.c_p_list_tab {
			max-width: 235px;
			width: 100%;
			background: #EBEBEB;
			margin-right: calc(3% / 5);
			margin-bottom: 5px;
			transition: all .3s;
			padding: 15px 20px;
			box-sizing: border-box;
			text-align: center;
			cursor: pointer;
			
			@include mq() {
				padding: 10px;
			}
			
			&:hover {
				background: #00A0E2;
				color: #fff;					
			}
			
			&:last-child {
				margin-right: 0;
			}
			
			&.current {
				background: #00A0E2;
				color: #fff;
			}
			
			@include mq(634) {
				font-size: 1.3rem;
				margin-right: calc(3% / 3);
				max-width: inherit;
				width: 32.6%;

				&:nth-child(3n) {
					margin-right: 0;
				}

			}
			
			@include mq(330) {
				margin-right: calc(3% / 2);
				width: 49.2%;
				
				&:nth-child(3n) {
					margin-right: auto;
				}
				
				&:nth-child(2n) {
					margin-right: 0;
				}
				
			}
			
			a {
				display: block;
				padding: 10px 20px;
				text-align: center;
				/*transition: opacity .3s;

				@include pc_hover {
					opacity: .7;
				}*/

				@include mq() {
					padding: 10px;
				}

			}
			
		}
		
	}
	
}

.csr_p_list {
	display: none;
	
	&.c_p_list_active {
		display: block;
	}
	
	li {
		border-bottom: 1px solid #888;
		
		a {
			display: block;
			padding: 20px 0;
			transition: opacity .3s;
			
			@include pc_hover {
				opacity: .7;
			}

			@include mq() {
				padding: 10px 0;
			}
			
		}
		
		&:first-of-type {
			a {
				padding-top: 10px;
			}
		}
		
	}
	
	.csr_p_list_block {
		display: -webkit-flex;
		display: flex;
		align-items: flex-start;
		
		@include mq() {
			flex-wrap: wrap;
		}
		
		.csr_p_list_date {
			width: 90px;
			flex-basis: 90px;
			flex-shrink: 0;
		}
		
		.csr_p_list_ttl {
			margin-left: 15px;
			
			@include mq() {
				margin-left: 0;
				width: 100%;
			}
			
		}
		
		.ico_new {
			margin-left: 20px;
			font-size: 1.2rem;
			font-weight: bold;
			line-height: 1;
			display: inline-block;
			padding: 5px 10px;
			border: 1px solid;
			color: #FF0000;
		}
		
	}
	
}
