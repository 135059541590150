.top {
    /************************************************

        MV

    *************************************************/
    .mv {

        video {
            width: 100%;
        }
    }

    /************************************************

        新着情報

    *************************************************/
    .sec_news {
        background: #fff;
        padding: 40px 20px 0;
        
        @include mq() {
            padding: 20px 10px 0;
        }
    }

    .news_block {
        margin: 0 auto;
        max-width: 1100px;
        background: #fff;
        display: -webkit-flex;
        display: flex;
        
        @include mq() {
            display: block;
            padding: 0 10px;
        }

        .news_col_l {
            margin-right: 35px;
            width: 280px;
            flex-basis: 280px;
            flex-shrink: 0;
            
            @include mq() {
                margin-right: 0;
                width: 100%;
                flex-basis: 100%;
            }
        }

        .news_col_r {
            width: 100%;
        }

        .news_hgroup {
            display: -webkit-flex;
            display: flex;
            align-items: flex-end;
            
            @include mq() {
                justify-content: center;
            }

            .news_hgroup_ttl {
                font-size: 4.2rem;
                line-height: 1.3;
                color: $blue01;
                margin-right: 20px;
                font-weight: normal;
                
                @include mq() {
                    font-size: 2.4rem;
                    margin-right: 10px;
                }
            }

            .news_hgroup_sub {
                font-size: 3rem;
                line-height: 1.3;
                color: $blue01;
                //font-weight: bold;
                
                @include mq() {
                    font-size: 1.6rem;
                }
            }
        }

        .news_btn_more {
            margin-top: 20px;
            
            @include mq() {
                margin-top: 15px;
            }

            a {
                display: block;
                border: 1px solid $blue01;
                color: $blue01;
                padding: 3px 15px;
                text-align: center;
                font-size: 2.3rem;
                font-weight: bold;
                position: relative;
                overflow: hidden;
                transition: color .3s, background .3s;
                box-sizing: border-box;
                
                @include mq() {
                    font-size: 1.6rem;
                    max-width: 200px;
                    margin: 0 auto;
                }

                @include pc_hover {
                    color: #fff;
                    //background: $blue01;

                    &::before {
                        transform: scaleY(1);
                    }

                    .ico_news .ico .cls-1 {
                        fill: #fff;
                    }

                    .ico_arrow01::after {
                        border-color: transparent transparent transparent #fff;
                    }
                }

                &::before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: $blue01;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    transition: transform .3s;
                    transform-origin: bottom;
                    //transform: rotateX(90deg);
                    transform: scaleY(0);
                }

            }

            .ico_news {
                position: relative;
                display: block;
                min-height: 45px;
                line-height: 45px;
                
                @include mq() {
                    min-height: 40px;
                    line-height: 40px;
                }

                .ico {
                    display: block;
                    width: 33px;
                    height: 45px;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 0;
                    
                    @include mq() {
                        width: round(33px * .8);
                        height: round(45px * .8);
                    }

                    .cls-1{
                        fill: $blue01;
                        transition: fill .3s;
                    }
                }
            }

            .ico_arrow01 {
                position: relative;
                display: block;
                padding-left: 10px;

                &::after {
                    content: '';
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 12px 0 12px 12px;
                    border-color: transparent transparent transparent $blue01;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    transition: border-color .3s;
                    
                    @include mq() {
                        border-width: 8px 0 8px 8px;
                    }
                }
            }
        }
    }

    .timeline_block {

        li {
             border-bottom: 1px solid #888;

            &:first-of-type {

                a {
                    padding-top: 5px;
                }
                
                @include mq() {
                    margin-top: 25px;
                }
            }

            a {
                display: block;

                padding: 15px 0 5px;
                transition: opacity .3s;

                @include pc_hover {
                    opacity: .7;
                }
            }
        }

        .timeline_row {
            display: -webkit-flex;
            display: flex;
            align-items: flex-start;
            
            @include mq() {
                //display: block;
                flex-wrap: wrap;
            }
        }

        .timeline_date {
            width: 90px;
            flex-basis: 90px;
            flex-shrink: 0;
        }

        .timeline_category {
            display: -webkit-flex;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.2rem;
            line-height: 1;
            background: $blue01;
            color: #fff;
            padding: 5px 10px;
            min-width: 70px;
            box-sizing: border-box;
            margin-top: 2px;
            
            @include mq() {
                display: inline-block;
            }

            &._recruit {
                background: $orange01;
            }
        }

        .timeline_ttl {
            margin-left: 15px;
            
            @include mq() {
                margin-left: 0;
            }
        }

        .ico_new {
            margin-left: 20px;
            font-size: 1.2rem;
            font-weight: bold;
            line-height: 1;
            display: inline-block;
            padding: 5px 10px;
            border: 1px solid;
            color: $red01;
        }
    }

    .bnr_box {
        margin: 35px auto 0;
        padding: 0 20px;
        //box-sizing: border-box;
        text-align: center;
        //background: #275B97;
        max-width: 1100px;
        
        @include mq() {
            margin: 35px 10px 0;
        }
        
        a {
            transition: opacity .3s;
            
            @include pc_hover {
                opacity: .7;
            }
        }
		
		.bnr_snd_box {
			//max-width: 640px;
			max-width: 680px;
			//display: flex;
			//align-items: center;
			margin: 30px auto 0;
			
			a {
				display: block;
				transition: opacity .3s;

				@include pc_hover {
					opacity: .7;
				}
			}
			
			.bnr_snd_pic {
				max-width: 270px;
				width: 100%;
			}
			
			.bnr_snd_box__ttl {
				font-size: 1.8rem;
				margin-bottom: 5px;
			}
			
			.bnr_snd_box__2col {
				display: flex;
				align-items: center;
			}
			
			.bnr_snd_txt {
				text-align: left;
				margin-left: 15px;
								
				.bnr_snd_txt02 {
					font-size: 1.4rem;
					
					span {
						color: #E4011E;
					}
				}
				
				.bnr_snd_txt03 {
					font-weight: bold;
					font-size: 1.6rem;
					
					span {
						color: #E4011E;
					}
				}
				
			}
			
			@include mq(730) {
				max-width: inherit;
				//display: block;
				margin: 15px auto 0;
				
				.bnr_snd_box__2col {
					display: block;
				}
				
				.bnr_snd_pic {
					margin: 0 auto;
				}
				
				.bnr_snd_txt {
					text-align: center;
					margin-top: 5px;
					margin-left: 0;
				}
				
			}
			
			
		}
		
    }
    

    /************************************************

        事業内容

    *************************************************/
    .sec_business {
        margin-top: 50px;
        background: url(/images/bg_business.jpg) no-repeat center top / cover;
        padding: 100px 0 18%;
        position: relative;
        
        @include mq(1023) {
            padding: 50px 0 70px;
        }
        
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 50%;
            background: url(/images/bg_cover.png) repeat-x left bottom / 10px 100%;
            position: absolute;
            bottom: -2px;
            left: 0;
        }
        
        > * {
            position: relative;
            z-index: 1;
        }
        
        .br01 {
            display: inline-block;
            
            @include mq(1300) {
                display: none;
            }
        }
    }
    
    .business_2col {
        margin: 0 auto;
        max-width: 1460px;
        width: 95%;
        padding: 0 20px;
        display: -webkit-flex;
        display: flex;
        align-items: flex-start;
        
        @include mq(1300) {
            display: block;
            padding: 0 10px;
        }
        
        .business_col_l {
            margin-top: 15px;
            margin-right: 40px;
			width: 248px;
			flex-basis: 248px;
            //width: 447px;
            //flex-basis: 447px;
            flex-shrink: 0;
            
            @include mq(1300) {
                margin-top: 0;
            }
            
            @include mq(1023) {
                width: 225px;
                margin: 0 auto;
            }
        }
        
        .business_col_r {
            
            @include mq(1300) {
                margin-top: 20px;
            }
        }
    }
    
    .business_hgroup {
        display: -webkit-flex;
        display: flex;
        align-items: flex-end;
        
        @include mq(1023) {
            display: block;
            text-align: center;
            margin: 0 auto;
        }
        
        .business_hgroup_ttl {
            margin-right: 25px;
            
            @include mq(1023) {
                margin-right: 0;
            }
        }
        
        .business_hgroup_sub {
            color: #fff;
			font-size: 6rem;
			font-weight: bold;
            //font-size: 3rem;
            line-height: 1;
            letter-spacing: 2px;
            
            @include mq(1023) {
				font-size: 3rem;
                //font-size: 1.6rem;
                //margin-top: 15px;
            }
        }
    }
    
    .business_lead {
        color: #fff;
        line-height: 1.5;
        font-size: 2.8rem;
        letter-spacing: 2px;
        
        @include mq(1023) {
            font-size: 1.8rem;
            line-height: 1.75;
        }
    }
    
    .business_btn_more {
        margin: 30px 0 0 20px;

        @include mq(1023) {
            margin: 15px auto 0;
        }

        a {
            display: inline-block;
            border: 1px solid $blue01;
            color: $blue01;
            padding: 7px 15px;
            text-align: center;
            font-size: 2.3rem;
            font-weight: bold;
            position: relative;
            overflow: hidden;
            transition: color .3s, background .3s;
            box-sizing: border-box;
            background: #fff;

            @include mq(1023) {
                font-size: 1.6rem;
                max-width: 250px;
                margin: 0 auto;
            }
            
            @include pc_hover {
                color: #fff;
                //background: $blue01;

                &::before {
                    transform: scaleY(1);
                }

                .ico_business .ico .cls-1 {
                    fill: #fff;
                }

                .ico_business .ico .cls-2 {
                    fill: $blue01;
                }

                .ico_arrow01::after {
                    border-color: transparent transparent transparent #fff;
                }
            }

            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background: $blue01;
                position: absolute;
                left: 0;
                bottom: 0;
                transition: transform .3s;
                transform-origin: bottom;
                //transform: rotateX(90deg);
                transform: scaleY(0);
            }

        }

        .ico_business {
            position: relative;
            display: block;
            min-height: 28px;
            line-height: 28px;
            padding-left: 50px;

            @include mq() {
                min-height: 24px;
                line-height: 24px;
                padding-left: 35px;
            }

            .ico {
                display: block;
                width: 40px;
                height: 28px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                font-size: 0;

                @include mq() {
                    width: round(40px * .8);
                    height: round(28px * .8);
                }

                .cls-1{
                    fill: $blue01;
                    transition: fill .3s;
                }

                .cls-2{
                    fill: #fff;
                    transition: fill .3s;
                }
            }
        }

        .ico_arrow01 {
            position: relative;
            display: block;
            padding-left: 10px;
            padding-right: 27px;

            &::after {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 12px 0 12px 12px;
                border-color: transparent transparent transparent $blue01;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                transition: border-color .3s;

                @include mq() {
                    border-width: 8px 0 8px 8px;
                }
            }
        }
    }
    
    .business_link_list_block {
        margin: 50px auto 0px;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 1360px;
        padding: 0 40px;
        
        @include mq(1023) {
            margin: 25px auto 0;
            justify-content: flex-start;
            padding: 0 10px;
        }
        
        li {
            width: 32%;
            margin-right: 2%;
            line-height: 1;
            position: relative;
            height: 0;
            padding-bottom: 22%;
            overflow: hidden;
            
            @include mq(1023) {
                width: 49%;
                padding-bottom: 34%;
            }
            
            @include pc_hover {
                
                &::after {
                    opacity: 0;
                }
                
                .photo {
                    transform: scale(1.05);
                }
            }
            
            &:nth-of-type(3),
            &:nth-of-type(5) {
                margin-right: 0;
                
                @include mq(1023) {
                    margin-right: 2%;
                }
            }
            
            &:nth-of-type(n+4) {
                margin-top: 2%;
            }
            
            &:nth-of-type(even) {
                
                @include mq(1023) {
                    margin-right: 0;
                }
            }
            
            &:nth-of-type(n+3) {
                
                @include mq(1023) {
                    margin-top: 2%;
                }
            }
            
            &::before {
                content: '';
                width: calc(100% - 25px);
                height: calc(100% - 25px);
                border: 2px solid #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
                
                @include mq(1023) {
                    width: calc(100% - 15px);
                    height: calc(100% - 15px);
                }
            }
            
            &::after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: #000;
                opacity: .3;
                z-index: 1;
                transition: opacity .5s;
            }
            
            .photo {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
                transition: transform .5s;
            }
            
            a {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 3;
                font-size: 3.2rem;
                letter-spacing: 3px;
                color: #fff;
                display: -webkit-flex;
                display: flex;
                align-items: center;
                justify-content: center;
                
                @include mq(1023) {
                    font-size: 1.8rem;
                }
            }
        }
    }
    
	.business_movie {
		margin: 50px auto 0;
		max-width: 1360px;
		padding: 0 40px;
		
		@include mq(1023) {
			margin: 25px auto 0;
			padding: 0 10px;
		}
		
		.business_movie_list {
			display: -webkit-flex;
			display: flex;
			
			@include mq(1023) {
				display: block;
			}

			li {
				//position: relative;
				width: 33.33%;
				//padding-top: 56.25%;
				//padding-top: 17.15%;
				margin-right: calc(6% / 3);
				
				&:last-child {
					margin-right: 0;
				}
				
				@include mq(1023) {
					width: 100%;
					margin-right: 0;
					//padding-top: 54.15%;
					
					+ li {
						margin-top: 1.5em;
					}
					
				}

				video {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
			
			.business_movie_thumb {
				position: relative;
				//padding-top: 56.25%;
				//padding-top: 17.15%;
				padding-top: 54.15%;
				
				@include mq(1023) {
					padding-top: 54.15%;
				}
				
			}
			
			.business_movie_ttl {
				margin-top: .4em;
				text-align: center;
				font-size: 1.8rem;
				line-height: 1.3;
				font-weight: bold;
				color: #fff;
				background: rgba(51, 51, 51, .7);
				border-radius: 5px;
				padding: 10px;
				
				@include mq() {
					font-size: 1.6rem;
				}
				
			}
			
		}
		
		
	}
	
	
	
	
    /************************************************

        製品案内

    *************************************************/
    
    .sec_product {
        margin-top: 20px;
        background: url(/images/bg_product.jpg) no-repeat center top / cover;
        padding-top: 30px;
        position: relative;
        padding-bottom: 100px;
        
        @include mq(1570) {
            padding-top: 50px;
            padding-bottom: 75px;
        }
        
        @include mq() {
            padding-bottom: 40px;
        }
        
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 70%;
            background: url(/images/bg_cover.png) repeat-x left bottom / 10px 100%;
            position: absolute;
            bottom: -3px;
            left: 0;
        }
        
        > * {
            position: relative;
            z-index: 1;
        }
    }
    
    .sec_product_inner {
        margin: 0 auto;
        max-width: 1600px;
        width: 95%;
        padding: 0 20px;
        
        @include mq() {
            padding: 0 10px;
        }
    }
    
    .product_2col01 {
        margin: 0 0 0 auto;
        max-width: 1500px;
        //width: 95%;
        display: -webkit-flex;
        display: flex;
        align-items: flex-start;
        
        @include mq(1570) {
            display: block;
            //padding: 0 10px;
        }
        
        .product_col_l01 {
            margin-top: 15px;
            margin-right: 40px;
            margin-left: auto;
			width: 248px;
			flex-basis: 248px;
            //width: 407px;
            //flex-basis: 407px;
            flex-shrink: 0;
            
            @include mq(1570) {
                margin-top: 0;
                margin-left: 0;
            }
            
            @include mq(1023) {
                width: 179px;
                margin: 0 auto;
            }
        }
        
        .product_col_r01 {
            width: 100%;
            
            @include mq(1300) {
                margin-top: 20px;
            }
        }
    }
    
    .product_2col02 {
        display: -webkit-flex;
        display: flex;
        align-items: center;
        
        @include mq() {
            display: block;
        }
        
        .product_col_l02 {
            margin-right: 50px;
            
            @include mq(1200) {
                width: 49%;
                margin-right: 2%;
            }
            
            @include mq() {
                width: 100%;
            }
        }
        
        .product_col_r02 {
            width: 400px;
            flex-basis: 400px;
            flex-shrink: 0;
            
            @include mq(1200) {
                width: 49%;
                flex-basis: 49%;
            }
            
            @include mq() {
                width: 100%;
                margin-top: 20px;
            }
        }
    }
    
    .product_hgroup {
        display: -webkit-flex;
        display: flex;
        align-items: flex-end;
        
        @include mq(1023) {
            display: block;
            text-align: center;
            margin: 0 auto;
        }
        
        .product_hgroup_ttl {
            margin-right: 25px;
            
            @include mq(1023) {
                margin-right: 0;
            }
        }
        
        .product_hgroup_sub {
            color: #fff;
			font-size: 6rem;
			font-weight: bold;
            //font-size: 3rem;
            line-height: 1;
            letter-spacing: 2px;
            
            @include mq(1023) {
				font-size: 3rem;
                //font-size: 1.6rem;
                //margin-top: 15px;
            }
        }
    }
    
    .product_lead {
        color: #fff;
        line-height: 1.5;
        font-size: 2.8rem;
        letter-spacing: 2px;
        
        @include mq(1023) {
            font-size: 1.8rem;
            line-height: 1.75;
        }
    }
    
    .product_btn_more {
        margin: 30px 0 0 20px;

        @include mq(1023) {
            margin: 15px auto 0;
        }

        a {
            display: inline-block;
            border: 1px solid $blue01;
            color: $blue01;
            padding: 10px 15px;
            text-align: center;
            font-size: 2.3rem;
            font-weight: bold;
            position: relative;
            overflow: hidden;
            transition: color .3s, background .3s;
            box-sizing: border-box;
            background: #fff;

            @include mq(1023) {
                font-size: 1.6rem;
                max-width: 250px;
                margin: 0 auto;
            }
            
            @include pc_hover {
                color: #fff;
                //background: $blue01;

                &::before {
                    transform: scaleY(1);
                }

                .ico_product .ico .cls-1 {
                    fill: #fff;
                }

                .ico_product .ico .cls-2 {
                    fill: #fff;
                    stroke: $blue01;
                }

                .ico_arrow01::after {
                    border-color: transparent transparent transparent #fff;
                }
            }

            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background: $blue01;
                position: absolute;
                left: 0;
                bottom: 0;
                transition: transform .3s;
                transform-origin: bottom;
                //transform: rotateX(90deg);
                transform: scaleY(0);
            }

        }

        .ico_product {
            position: relative;
            display: block;
            min-height: 25px;
            line-height: 25px;
            padding-left: 50px;

            @include mq() {
                min-height: 21px;
                line-height: 21px;
                padding-left: 35px;
            }

            .ico {
                display: block;
                width: 43px;
                height: 25px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                font-size: 0;

                @include mq() {
                    width: round(43px * .8);
                    height: round(25px * .8);
                }

                .cls-1{
                    fill: $blue01;
                    transition: fill .3s;
                }

                .cls-2{
                    fill: $blue01;
                    transition: fill .3s, stroke .3s;
                    stroke: #fff;
                    stroke-miterlimit: 10;
                    stroke-width: 0.74px;
                }
            }
        }

        .ico_arrow01 {
            position: relative;
            display: block;
            padding-left: 10px;
            padding-right: 27px;

            &::after {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 12px 0 12px 12px;
                border-color: transparent transparent transparent $blue01;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                transition: border-color .3s;

                @include mq() {
                    border-width: 8px 0 8px 8px;
                }
            }
        }
    }
    
    .pickup_block {
        margin-top: -20px;
        
        @include mq(1300) {
            margin-top: 20px;
        }
        
        @include mq() {
            margin-top: 40px;
        }
        
        .pickup_ttl {
            width: 100%;
            overflow: hidden;
            
            img {
                width: 1600px;
            }
        }
        
        .pickup_list {
            margin: 30px 25px 0;
            //display: -webkit-flex;
            //display: flex;
            
            @include mq(1300) {
                flex-wrap: wrap;
            }
            
            .pickup_list_item {
                //width: 18%;
                //margin-right: 2.5%;
                background: #fff;
                box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, .3);
                margin: 10px;
                
                /*@include mq(1300) {
                    width: 32%;
                    margin-right: 2%;
                }
                
                @include mq() {
                    width: 49%;
                    margin-right: 2%;
                }
                
                &:nth-of-type(3) {
                    
                    @include mq(1300) {
                        margin-right: 0;
                    }
                    
                    @include mq() {
                        margin-right: 2%;
                    }
                }
                
                &:nth-of-type(2n) {
                    
                    @include mq() {
                        margin-right: 0;
                    }
                }
                
                &:nth-of-type(n+4) {
                    
                    @include mq(1300) {
                        margin-top: 2%;
                    }
                }
                
                &:nth-of-type(n+3) {
                    
                    @include mq() {
                        margin-top: 2%;
                    }
                }
                
                &:last-of-type {
                    margin-right: 0;
                }*/
                
                @include pc_hover {
                    
                    .pickup_photo {
                        
                        img {
                            transform: scale(1.1);
                        }
                    }
                    
                    .pickup_text {
                        
                        &::before {
                            transform: translate(-5px, -5px);
                        }
                        
                        &::after {
                            transform: translate(5px, -5px);
                        }
                        
                        > div {

                            &::before {
                                transform: translate(-5px, 5px);
                            }

                            &::after {
                                transform: translate(5px, 5px);
                            }
                        }
                    }
                }
                
                .pickup_photo {
                    width: 100%;
                    height: 0;
                    position: relative;
                    padding-bottom: 72%;
                    overflow: hidden;
                    
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition: transform .5s;
                    }
                }
                
                .pickup_text {
                    padding: 15px;
                    position: relative;
                    box-sizing: border-box;
                    font-size: 1.8rem;
                    text-align: center;
                    line-height: 1.3;
                    
                    @include mq() {
                        padding: 10px;
                    }
                    
                    &::before,
                    &::after {
                        content: '';
                        display: block;
                        width: 18px;
                        height: 18px;
                        position: absolute;
                        top: 15px;
                        transition: transform .5s;
                        
                        @include mq() {
                            top: 10px;
                        }
                    }
                    
                    &::before {
                        border-top: 2px solid $blue01;
                        border-left: 2px solid $blue01;
                        left: 15px;
                        
                        @include mq() {
                            left: 10px;
                        }
                    }
                    
                    &::after {
                        border-top: 2px solid $blue01;
                        border-right: 2px solid $blue01;
                        right: 15px;
                        
                        @include mq() {
                            right: 10px;
                        }
                    }
                    
                    > div {
                        position: relative;
                        
                        &::before,
                        &::after {
                            content: '';
                            display: block;
                            width: 18px;
                            height: 18px;
                            position: absolute;
                            bottom: 0;
                            transition: transform .5s;
                        }

                        &::before {
                            border-bottom: 2px solid $blue01;
                            border-left: 2px solid $blue01;
                            left: 0;
                        }

                        &::after {
                            border-bottom: 2px solid $blue01;
                            border-right: 2px solid $blue01;
                            right: 0;
                        }
                        
                        > p {
                            padding: 8px 22px 13px;
                            display: -webkit-flex;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;

                            &::after {
                                content: '';
                                display: block;
                                width: 22px;
                                height: 22px;
                                background: url("../images/ico_arrow06.svg") no-repeat center / 22px 22px;
                                position: absolute;
                                top: 50%;
                                right: 0;
                                transform: translate(7px, -50%);
                            }
                        }
                    }
                    
                    .small {
                        font-size: 1.4rem;
                        line-height: 1.3;
                        display: block;
                        padding-bottom: 7px;
                    }
                    
                    .ico_arrow02 {
                        //display: inline-block;
                    }
                }
                
                a {
                    
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }
    
    /************************************************

        企業情報 CSR情報

    *************************************************/
    .sec_2col {
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
        max-width: 2000px;
        margin: 20px auto 0;
        
        @include mq(1150) {
            display: block;
        }
        
        > * {
            width: 48%;
            
            @include mq(1150) {
                width: 100%;
            }
        }
        
        .sec_company,
        .sec_csr {
            position: relative;
            overflow: hidden;
            padding: 5% 0;
            box-sizing: border-box;
            
            @include mq() {
                padding: 20px;
            }
        }
        
        .sec_company {
            
            .sec_bg_photo {
                background: url(/images/company01.jpg) no-repeat center / cover;
            }
            
            .sec_box {
                background: rgba(#0066E2, .7);
            }
            
            .box_ttl {
                
                .box_ttl_inner {
                    
                    @include mq(1023) {
                        width: 321px;
                    }
                }
                
                img {
                    
                    @include mq(1023) {
                        width: 68.5%;
                        max-width: 220px;
                    }
                }
            }
            
            .sec_btn_more {
                margin: 5px auto 0;

                @include mq(1023) {
                    margin: 15px auto 0;
                }

                a {
                    display: inline-block;
                    border: 1px solid $blue01;
                    color: $blue01;
                    padding: 3px 15px;
                    text-align: center;
                    font-size: 2.3rem;
                    font-weight: bold;
                    position: relative;
                    overflow: hidden;
                    transition: color .3s, background .3s;
                    box-sizing: border-box;
                    background: #fff;

                    @include mq(1023) {
                        font-size: 1.6rem;
                        max-width: 250px;
                        margin: 0 auto;
                    }

                    @include pc_hover {
                        color: #fff;
                        //background: $blue01;

                        &::before {
                            transform: scaleY(1);
                        }

                        .ico_company .ico .cls-1 {
                            fill: #fff;
                        }

                        .ico_company .ico .cls-2 {
                            fill: $blue01;
                        }

                        .ico_arrow01::after {
                            border-color: transparent transparent transparent #fff;
                        }
                    }

                    &::before {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 100%;
                        background: $blue01;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        transition: transform .3s;
                        transform-origin: bottom;
                        //transform: rotateX(90deg);
                        transform: scaleY(0);
                    }

                }

                .ico_company {
                    position: relative;
                    display: block;
                    min-height: 45px;
                    line-height: 45px;
                    padding-left: 46px;

                    @include mq() {
                        min-height: 36px;
                        line-height: 36px;
                        padding-left: 36px;
                    }

                    .ico {
                        display: block;
                        width: 36px;
                        height: 40px;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 0;

                        @include mq() {
                            width: round(36px * .8);
                            height: round(40px * .8);
                        }
                        
                        .cls-1{
                            fill: $blue01;
                            transition: fill .3s;
                        }

                        .cls-2{
                            fill: #fff;
                            transition: fill .3s;
                        }
                    }
                }

                .ico_arrow01 {
                    position: relative;
                    display: block;
                    padding-left: 10px;
                    padding-right: 27px;

                    &::after {
                        content: '';
                        display: block;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 12px 0 12px 12px;
                        border-color: transparent transparent transparent $blue01;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        transition: border-color .3s;

                        @include mq() {
                            border-width: 8px 0 8px 8px;
                        }
                    }
                }
            }
        }
        
        .sec_csr {
            
            @include mq(1150) {
                margin-top: 20px;
            }
            
            .sec_bg_photo {
                background: url(/images/csr01.jpg) no-repeat center / cover;
            }

            .sec_box {
                background: rgba($orange01, .7);
            }
            
            .sec_btn_more {
                margin: 5px auto 0;

                @include mq(1023) {
                    margin: 15px auto 0;
                }

                a {
                    display: inline-block;
                    border: 1px solid $orange01;
                    color: $orange01;
                    padding: 3px 15px;
                    text-align: center;
                    font-size: 2.3rem;
                    font-weight: bold;
                    position: relative;
                    overflow: hidden;
                    transition: color .3s, background .3s;
                    box-sizing: border-box;
                    background: #fff;

                    @include mq(1023) {
                        font-size: 1.6rem;
                        max-width: 250px;
                        margin: 0 auto;
                    }

                    @include pc_hover {
                        color: #fff;
                        //background: $blue01;

                        &::before {
                            transform: scaleY(1);
                        }

                        .ico_csr .ico .cls-1 {
                            fill: #fff;
                        }

                        .ico_csr .ico .cls-2 {
                            stroke: $orange01;
                        }

                        .ico_arrow01::after {
                            border-color: transparent transparent transparent #fff;
                        }
                    }

                    &::before {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 100%;
                        background: $orange01;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        transition: transform .3s;
                        transform-origin: bottom;
                        //transform: rotateX(90deg);
                        transform: scaleY(0);
                    }

                }

                .ico_csr {
                    position: relative;
                    display: block;
                    min-height: 45px;
                    line-height: 45px;
                    padding-left: 46px;

                    @include mq() {
                        min-height: 40px;
                        line-height: 40px;
                        padding-left: 36px;
                    }

                    .ico {
                        display: block;
                        width: 36px;
                        height: 40px;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 0;

                        @include mq() {
                            width: round(36px * .8);
                            height: round(40px * .8);
                        }

                        .cls-1{
                            fill: $orange01;
                            transition: fill .3s;
                        }

                        .cls-2{
                            fill: none;
                            stroke: #fff;
                            stroke-linecap: round;
                            stroke-miterlimit: 10;
                            stroke-width: 0.92px;
                            transition: stroke .3s;
                        }
                    }
                }

                .ico_arrow01 {
                    position: relative;
                    display: block;
                    padding-left: 10px;
                    padding-right: 27px;

                    &::after {
                        content: '';
                        display: block;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 12px 0 12px 12px;
                        border-color: transparent transparent transparent $orange01;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        transition: border-color .3s;

                        @include mq() {
                            border-width: 8px 0 8px 8px;
                        }
                    }
                }
            }
        }
        
        .sec_bg_photo {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        
        .box_ttl {
            display: -webkit-flex;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            
            .box_ttl_text {
                color: #fff;
				font-size: 3.8rem;
				font-weight: bold;
                //font-size: 2.8rem;
                letter-spacing: 1px;
                //margin-top: 5px;
				
				@include mq() {
					font-size: 3.5rem;
				}
				
            }
        }
        
        .sec_box {
            position: relative;
            width: 350px;
            padding: 25px;
            box-sizing: border-box;
            
            @include mq() {
                width: 100%;
            }
        }
        
        .sec_btn_more {
            text-align: center;
        }
    }
}







