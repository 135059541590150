/************************************************

	警備協力会社募集ページ

*************************************************/
.applicant {
	.applicant_box {
		max-width: 1200px;
		margin: 0 auto;
		padding: 0 10px;
		
		.applicant_box__ttl {
			font-size: 2.8rem;
			text-align: left;
			color: #4d4d4d;
			margin-bottom: 30px;
			
			@include mq(1023) {
				font-size: 2.2rem;
				margin-bottom: 20px;
			}
			
		}
		
		.applicant_box__txt {
			text-align: left;
		}
		
		.applicant_txt--small {
			font-size: 1.4rem;
			text-indent: -0.5em;
			padding-left: 0.5em;
			display: inline-block;
		}
		
		.applicant_box__tel {
			display: flex;
			justify-content: center;
			
			dl {
				display: flex;
				align-items: baseline;
				font-weight: bold;
				color: $blue02;
				
				dt {
					
					font-size: 2.3rem;
					margin-right: 15px;
				}
				
				dd {
					font-size: 2.3rem;
					
					span {
						font-size: 4rem;
					}
					
				}
				
			}
			
			@include mq() {
				margin-top: 15px;
				
				dl {
					display: block;
					
					dt {
						font-size: 2rem;
						text-align: center;
						margin-right: 0;
						line-height: 1;
						margin-bottom: 7px;
					}
					
					dd {
						font-size: 2rem;
						line-height: 1;
						
						span {
							font-size: 3rem;
						}
						
					}
					
					
				}
			}
			
		}
		
		.applicant_box__btn {
			display: flex;
			justify-content: center;
			margin-top: 15px;
			
			.applicant_box__btn--entry {
				max-width: 255px;
				width: 100%;
				border: 1px solid $blue01;
				
				a {
					display: block;
					background: $blue01;
					color: #fff;
					padding: 14px;
					text-align: center;
					transition: .3s all;
					
					@include pc_hover() {
						background: #fff;
						color: $blue01;
					}
					
				}
			}
			
			@include mq() {
				margin-top: 25px;
			}
			
		}
		
		
	}
}