/************************************************

	新着情報一覧

*************************************************/
.news_detail {
	.news_detail_box {
		max-width: 1200px;
		margin: 0 auto;
		padding: 0 10px;
	}
	
	.news_detail_box_wrap {
		padding: 0 5px;
		margin-bottom: 50px;
		
		@include mq() {
			margin-bottom: 25px;
		}
		
	}
	
	.news_detail_label {
		margin-bottom: 20px;
		
		@include mq() {
			margin-bottom: 10px;
		}
		
		.news_list_block {
			display: -webkit-flex;
			display: flex;
			align-items: flex-start;
			
			.news_list_date {
				width: 90px;
				flex-basis: 90px;
				flex-shrink: 0;
			}
			
			.news_list_category {
				display: -webkit-flex;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 1.2rem;
				line-height: 1;
				background: #00A0E2;
				color: #fff;
				padding: 5px 10px;
				min-width: 70px;
				box-sizing: border-box;
				margin-top: 2px;
			}
			
			.ico_new {
				margin-left: 20px;
				font-size: 1.2rem;
				font-weight: bold;
				line-height: 1;
				display: inline-block;
				padding: 5px 10px;
				border: 1px solid;
				color: #FF0000;
				vertical-align: top;
			}
			
			
		}
	}
	
	.news_detail_ttl {
		font-size: 2.5rem;
		margin-bottom: 40px;
		
		@include mq() {
			margin-bottom: 20px;
		}
		
	}
	
	.news_detail_txt {
		margin-bottom: 30px;
		
		@include mq() {
			margin-bottom: 15px;
		}
		
		a {
			color: #005B97;
			transition: opacity .3s;
			font-weight: bold;
			position: relative;
			
			&::after {
				content: "＞";
				margin-left: 10px;
			}
			

			@include pc_hover {
				opacity: .7;
			}
		}
		
	}
	
	.news_detail_pic_wrap {
		display: flex;
		flex-wrap: wrap;
		
		@include mq() {
			display: block;
		}
		
		.news_detail_pic {
			margin-right: calc(8% / 2);
			margin-bottom: 30px;
			max-width: 570px;
			width: 48%;
			
			&:nth-child(2n) {
				margin-right: 0;
			}
			
			@include mq() {
				max-width: inherit;
				width: 100%;
				margin-bottom: 15px;
			}
			
		}
		
	}
	
	.news_detail_btn_pdf {
		background: #00A0E2;
		border-radius: 8px;
		font-size: 2.3rem;
		color: #fff;
		max-width: 275px;
		width: 100%;
		margin: 0 auto;
		text-align: center;
		transition: opacity .3s;
		
		@include pc_hover {
			opacity: .7;
		}
		
		@include mq() {
			max-width: 80%;
			margin: 0 auto;
			font-size: 2.1rem;
		}
		
		a {
			display: block;
			padding: 10px;
			position: relative;
			
			&::after {
				content: "";
				position: absolute;
				background: url("../images/ico_pdf04.png") no-repeat right center/28px;
				width: 28px;
				padding-bottom: 37px;
				right: 10px;
			}
			
		}
		
	}
	
	.news_detail_btn_back {
		background: #00A0E2;
		color: #fff;
		max-width: 650px;
		font-size: 2.3rem;
		margin: 0 auto;
		text-align: center;
		transition: opacity .3s;
		
		@include pc_hover {
			opacity: .7;
		}
		
		@include mq() {
			font-size: 2.1rem;
		}
		
		a {
			display: block;
			padding: 15px;
			position: relative;
			
			@include mq() {
				padding: 10px 15px;
			}
			
		}
		
	}
	
}



