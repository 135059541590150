$gothic: "Helvetica Neue",
    Arial,
    "Hiragino Kaku Gothic ProN",
    "Hiragino Sans",
    "BIZ UDPGothic",
    Meiryo,
    sans-serif;

$mincho: "Yu Mincho",
		 "YuMincho",
		 serif;

$blue01: #00A0E2;
$blue02: #005B97;
$blue03: #00A0E9;
$orange01: #FFA00C;
$orange02: #EA5514;
$red01: #FF0000;
$red02: #E95377;
$gray01: #EBEBEB;
$green01: #009944;

$media_tb: 1024;
$media_short: 767;

@mixin pc_hover() {
	
	@media (hover: hover) {
		
		&:hover {
			@content;
		}
	}
	
	@include mq_ie {
		
		&:hover {
			@content;
		}
		
	}
}

@mixin mq_ie() {
	@at-root _:-ms-input-placeholder, :root & {
		@content;
	}
}

@mixin bold() {
	font-weight: bold;
}

@mixin mq($size:768, $minmax:max) {
	@media screen and (#{$minmax}-width: #{$size}px) {
		@content;
	}
}
