/************************************************

	社長あいさつ

*************************************************/
.greeting_box {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 10px 70px;
	
	@include mq() {
		padding: 0 10px 35px;
	}
	
	h3 {
		font-size: 2.5rem;
		border-bottom: 1px solid;
		padding-left: 15px;
		color: #005B97;
		margin-bottom: 37px;
		
		@include mq() {
			padding-left: 10px;
			font-size: 2.1rem;
			margin-bottom: 15px;
		}
		
	}
}

.greeting_box_txt {
	margin-top: 50px;
	
	p {
		line-height: 1.75em;
		margin-bottom: 10px;
		
		&:last-child {
			margin-bottom: 0;
		}
		
	}
}

.greeting_name {
	margin-top: 30px;
	text-align: right;
	
	@include mq() {
		margin-top: 20px;
	}
	
	p {
		margin-bottom: 0;
	}
	
	.greeting_br {
		display: none;
		
		@include mq(320) {
			display: inline;
		}
		
	}
	
	.greeting_name--large {
		font-weight: bold;
		font-size: 2rem;
		
		@include mq() {
			font-size: 1.9rem;
		}
		
	}
	
}

