/************************************************

	社員専用サイト

*************************************************/
.member {
	/* ヘッダー */
	.member_header {
		position: relative;
		background: url("../../images/member/img_mv_bg--pc.jpg") no-repeat left bottom / 100%;
		padding-bottom: 46.9%;
		z-index: 1;
		
		@include mq(840) {
			background: url("../../images/member/img_mv_bg--sp.jpg") no-repeat left bottom / 100%;
			padding-bottom: 45%;
		}
		
		.member_header_main-title {
			position: absolute;
			top: 0;
			left: 0;
			width: 540px;
			
			@include mq(840) {
				position: relative;
				max-width: 540px;
				width: 100%;
				margin-bottom: 15px;
			}
			
		}
		
		.member_header_logo {
			max-width: 165px;
			padding: 20px 35px;
			
			@include mq(840) {
				max-width: 115px;
				padding: 10px 15px;
			}
			
		}
		
		.member_header_title {
			position: relative;
			background-image: -moz-linear-gradient(25deg, $blue01 50%, transparent 50.1%);
			background-image: -webkit-linear-gradient(25deg, $blue01 50%, transparent 50.1%);
			background-image: linear-gradient(115deg, $blue01 50%, transparent 50.1%);
			color: #fff;
			max-width: 540px;
			
			@include mq(890) {
				max-width: 460px;
				width: 100%;
				background-image: -moz-linear-gradient(25deg, $blue01 51%, transparent 49.1%);
				background-image: -webkit-linear-gradient(25deg, $blue01 51%, transparent 49.1%);
				background-image: linear-gradient(115deg, $blue01 51%, transparent 49.1%);
			}
			
			&::after {
				content: "";
				display: block;
				background-image: -moz-linear-gradient(25deg, $blue01 52%, transparent 48.1%);
				background-image: -webkit-linear-gradient(25deg, $blue01 52%, transparent 48.1%);
				background-image: linear-gradient(115deg, $blue01 52%, transparent 48.1%);
				mix-blend-mode: hard-light;
				//width: 550px;
				width: 100%;
				padding-bottom: 115px;
				position: absolute;
				left: 0;
				bottom: -15px;
				z-index: -1;
				
				@include mq(890) {
					width: 100%;
					padding-bottom: 65px;
					bottom: -9px;
					
				}
				
			}
			
			h2 {
				padding: 15px 0 15px 50px;
				font-size: 1.6rem;
				line-height: 1.5;
				display: inline-block;
				
				@include mq(890) {
					padding: 8px 0 8px 15px;
					font-size: 1.2rem;
					line-height: 1.3;
				}
				
				span {
					display: block;
					font-size: 2.5rem;
					
					@include mq(890) {
						font-size: 2.1rem;
						line-height: 1.3;
					}
				}
			}
		}
		
		.member_header_caption {
			display: -webkit-flex;
			display: flex;
			justify-content: flex-end;
			position: absolute;
			top: 50px;
			right: 100px;
			
			@include mq(1090) {
				right: 50px;
			}
			
			@include mq(840) {
				position: relative;
				display: block;
				top: auto;
				right: auto;
				padding: 0 10px;
				text-align: right;
			}			
		}
		
		.member_header_text01 {
			font-family: $mincho;
			writing-mode: vertical-rl;
			-webkit-writing-mode: vertical-rl;
			font-size: 2.8rem;
			margin-right: 50px;
			
			@include mq(1090) {
				font-size: 2.3rem;
				margin-right: 25px;
			}
			
			@include mq(840) {
				writing-mode: inherit;
				-webkit-writing-mode: inherit;
				font-size: 2rem;
				margin-right: 0;
				margin-bottom: 10px;
				line-height: 1.5;
			}
			
		}
		
		.member_header_text02 {
			
			@include mq(1090) {
				width: 60%;
			}
			
			@include mq(840) {
				width: 55%;
				margin-left: auto;
			}
			
		}
		
	}
	
	/* コンテンツ */
	.container {
		background: url("../../images/member/img_bg01.png") repeat-y center / cover;
		
		@include mq() {
			//height: 100vh;
		}
	}
	
	.member_container {
		padding: 85px 10px 0;
		
		@include mq() {
			padding: 45px 10px 0;
		}
	}
	
	.member_search_list {
		margin-top: 50px;
		padding: 0 10px;
		
		@include mq() {
			margin-top: 30px;
		}
		
	}
	
	
	/* 見出し */
	.member_doc_title {
		background: $blue01;
		color: #fff;
		padding: 50px 10px 150px;
		
		@include mq() {
			//padding: 25px 10px 75px;
			padding: 15px 10px 65px;
		} 
		
		.member_doc_title__wrap {
			display: -webkit-flex;
			display: flex;
			justify-content: center;
			
			@include mq() {
				display: block;
			}
			
		}
		
		.member_doc_title_sp__img {
			display: none;
			
			@include mq() {
				display: block;
				max-width: 40%;
				margin: 0 auto;
			}
		}
		
		.member_doc_title__text {
			position: relative;
			padding-left: 245px;
			
			@include mq() {
				margin-top: 20px;
				padding-left: 0;
			}
			
			&::before {
				content: "";
				display: block;
				background: url("../../images/member/img01.png") no-repeat left center / 225px;
				width: 225px;
				height: 308px;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				z-index: 1;
				
				@include mq() {
					content: none;
				}
				
			}
			
			h2 {
				font-size: 3.5rem;
				line-height: 1.3;
				margin-bottom: 15px;
				
				@include mq() {
					font-size: 2.3rem;
					margin-bottom: 5px;
				}
				
			}
		}
	}
	
	.member_title01 {
		display: -webkit-flex;
		display: flex;
		align-items: center;
		background: linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba(160,244,255,1) 100%);
		border-radius: 15px 15px 0 0;
		padding: 40px 65px 20px;
		margin: 0 15px;
		
		@include mq() {
			padding: 20px 25px;
			margin: 0 10px;
		}
		
		h3, span {
			line-height: 1.3;
		}
		
		h3 {
			font-size: 3.5rem;
			letter-spacing: 1rem;
			color: #5B636B;
			margin-right: 15px;
			
			@include mq() {
				font-size: 2.8rem;
				margin-right: 5px;
			}
			
		}
		
		span {
			display: inline-block;
			position: relative;
			font-size: 1.8rem;
			color: $blue01;
			padding-left: 45px;
			
			@include mq() {
				font-size: 1.6rem;
				padding-left: 25px;
			}
			
			&::before {
				content: "";
				display: block;
				background: $blue01;
				width: 35px;
				height: 2px;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				
				@include mq() {
					width: 15px;
				}
				
			}
		}
	}
	
	.member_title02 {
		position: relative;
		background-image: -moz-linear-gradient(27deg, $blue01 90%, transparent 10.1%);
		background-image: -webkit-linear-gradient(27deg, $blue01 90%, transparent 10.1%);
		background-image: linear-gradient(117deg, $blue01 90%, transparent 10.1%);
		color: #fff;
		margin-bottom: 25px;
		
		@include mq() {
			background-image: -moz-linear-gradient(27deg, $blue01 89%, transparent 10.1%);
			background-image: -webkit-linear-gradient(27deg, $blue01 89%, transparent 10.1%);
			background-image: linear-gradient(117deg, $blue01 89%, transparent 10.1%);
		}
		
		&::after {
			content: "";
			display: block;
			background-image: -moz-linear-gradient(27deg, $blue01 90.4%, transparent 9.7%);
			background-image: -webkit-linear-gradient(27deg, $blue01 90.4%, transparent 9.7%);
			background-image: linear-gradient(117deg, $blue01 90.4%, transparent 9.7%);
			mix-blend-mode: hard-light;
			width: 100%;
			padding-bottom: 45px;
			position: absolute;
			left: 0;
			bottom: -8px;
			z-index: -1;
			opacity: .4;
			
			@include mq() {
				padding-bottom: 40px;
			}
			
		}

		h4 {
			padding: 10px 0 10px 50px;
			font-size: 1.8rem;
			line-height: 1.5;
			letter-spacing: .1em;
			display: inline-block;
			
			@include mq() {
				padding: 10px 0 10px 25px;
				line-height: 1.3;
			}
		}
	}
	
	#member_knowledge {
		.member_title02 {
			@include mq() {
				&::after {
					padding-bottom: 55px;
				}
			}
		}
	}
	
	.member_title03 {
		position: relative;
		padding-left: 20px;
		margin-bottom: 10px;
		line-height: 1.3;
		
		&::before {
			content: "";
			display: block;
			width: .9em;
			height: .9em;
			background: #000;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	
	
	/* ボックス */
	.member_box01 {
		position: relative;
		max-width: 1000px;
		margin: 0 auto;
		background: #fff;
		padding: 15px 0 50px;
		//padding: 15px 15px 50px;
		-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
		-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
		-ms-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
		z-index: 1;
		
		@include mq() {
			padding: 10px 0 30px;
		}
		
	}
	
	.member_box02 {
		position: relative;
		margin-top: 50px;
		
		@include mq() {
			margin-top: 35px;
		}
		
		.member_box02__wrap {
			max-width: 70%;
			margin: 0 auto;
			
			@include mq() {
				max-width: 90%;
			}
			
		}
	}
	
	#work_recital {
		&.member_box02 {
			margin-top: 30px;
			
			@include mq() {
				margin-top: 10px;
			}
		}
	}
	
	
	/* カラム */
	.member_col01 {
		display: -webkit-flex;
		display: flex;
		align-items: center;
		justify-content: center;
		
		@include mq() {
			display: block;
		}
		
		.member_col01__box {
			margin-right: calc(28% / 2);
			
			&:last-child {
				width: calc(100% - 255px - 14%);
				margin-right: 0;
				
				@include mq() {
					width: auto;
				}
				
			}
			
			&.member_thumb {
				width: 255px;
				flex-shrink: 0;
				
				@include mq() {
					width: 175px;
					margin: 0 auto;
				}
				
			}
			
			&.member_link_list {
				display: -webkit-flex;
				display: flex;
				
				@include mq() {
					justify-content: center;
					margin-top: 20px;
				}
				
				.member_list01 {
					width: 48%;
					margin-right: calc(8% / 2);
					
					&:nth-child(2n) {
						margin-right: 0;
					}
					
					@include mq() {
						width: 40%;
					}
				}
			}
		}
	}
	
	#member_project {
		.member_col01 {
			.member_col01__box {
				&.member_thumb {
					width: 265px;
				}
			}
		}
	}
	
	
	/* 画像 */
	.member_thumb--project_pic {
		max-width: 255px;
		width: 100%;
		margin: 0 auto;
	}
	
	/* テキスト */
	.member_catch_copy {
		margin-bottom: 20px;
	}
	
	.member_caption01 {
		text-align: center;
		margin-top: 10px;
		font-size: 1.4rem;
		line-height: 1.3;
	}
	
	
	/* リンク */
	.member_link01 {
		color: $blue02;
		text-decoration: underline;
		
		@include pc_hover {
			text-decoration: none;
		}
		
	}
	
	/* リスト */
	.member_list01 {
		display: -webkit-flex;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		
		li {
			margin-bottom: 15px;
			
			&:last-child {
				margin-bottom: 0;
			}
			
			@include mq() {
				text-align: center;
				margin-bottom: 10px;
			}
			
			/*width: 45%;
			margin-right: calc(20% / 2);
			margin-bottom: 15px;
			
			&:nth-child(2n) {
				margin-right: 0;
			}*/
		}
	}
	
	.member_list02 {
		display: -webkit-flex;
		display: flex;
		
		@include mq() {
			flex-wrap: wrap;
		}
		
		li {
			width: 23%;
			margin-right: calc(12% / 4);
			
			&:last-child {
				margin-right: 0;
			}
			
			@include mq() {
				width: 47%;
				margin-right: calc(12% / 2);
				margin-bottom: 15px;
				
				&:nth-child(2n) {
					margin-right: 0;
				}
				
			}
			
			.member_thumb {
				margin-top: 10px;
			}
		}
	}
	
	.member_result_list {
		display: -webkit-flex;
		display: flex;
		flex-wrap: wrap;
		
		li {
			width: 23.5%;
			margin-right: calc(8% / 4);
			text-align: center;
			margin-bottom: 15px;
			
			&:nth-child(4n) {
				margin-right: 0;
			}
			
			@include mq() {
				width: 48%;
				margin-right: calc(4% / 2);
				margin-bottom: 10px;
				
				&:nth-child(2n) {
					margin-right: 0;
				}
			}
			
		}
		
	}
	
	
	/* フォーム */
	input[type=text], input[type=password],select {
		padding: 6px;
		box-sizing: border-box;
		border: 2px solid #e6e6e6;
		font-size: 2rem;
	}
	
	.member_check_box {
		cursor: pointer;
		
		input[type=checkbox] {
			filter: alpha(opacity=0);
			-moz-opacity: 0;
			opacity: 0;
			-webkit-appearance: none;
			appearance: none;
			position: absolute;
			
			&:checked {
				+ .member_check_box__ico {
					&::after {
						opacity: 1;
					}
				}
			}
		}
		
		.member_check_box__ico {
			width: 1.5rem;
			height: 1.5rem;
			border: 2px solid #e6e6e6;
			background: #fff;
			display: inline-block;
			margin-right: 10px;
			vertical-align: bottom;
			position: relative;
			transform: translateY(-.3em);
			
			&::after {
				content: "";
				width: .9rem;
				height: .3rem;
				border-left: 3px solid $blue01;
				border-bottom: 3px solid $blue01;
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -75%) rotate(-45deg);
				opacity: 0;
			}
		}
	}
	
	.member_select01 {
		font-size: 1.6rem;
		//color: #808080;
		margin-top: 25px;
		cursor: pointer;
		
		@include mq() {
			margin-top: 5px;
		}
	}
	
	.member_login_caption01 {
		font-size: 1.6rem;
	}
	
	.member_login_ico--required {
		margin-left: 1em;
		color: $red01;
	}
	
	
	/* ボタン */
	.member_btn01 {
		text-align: center;
		color: #fff;
		max-width: 350px;
		//max-width: 380px;
		width: 100%;
		margin: 0 auto;
		
		a, button {
			display: block;
			background: linear-gradient(90deg, rgba(160,244,255,1) 0%, rgba(0,160,226,1) 40%);
			border-radius: 50px;
			padding: 15px;
			font-size: 2rem;
			font-weight: bold;
			line-height: 1;
			transition: .3s opacity;
			width: inherit;
			box-sizing: border-box;
			
			@include pc_hover {
				opacity: .7;
			}
			
			@include mq() {
				font-size: 1.8rem;
			}
		}
		
		span {
			display: block;
			background: linear-gradient(90deg, rgba(160,244,255,1) 0%, rgba(0,160,226,1) 40%);
			border-radius: 50px;
			padding: 15px;
			font-size: 2rem;
			font-weight: bold;
			line-height: 1;
			transition: .3s opacity;
			
			@include pc_hover {
				opacity: .7;
			}
			
			@include mq() {
				font-size: 1.8rem;
			}
		}
		
	}
		
	.w100 {
		max-width: 700px;
		width: 100%;
	}
	
	/* 検索ボックス */
	.member_search {
		margin-top: -50px;
		padding: 0 10px;
		
		.member_search_box01 {
			max-width: 75%;
			margin: 0 auto;
			
			@include mq() {
				max-width: 90%;
			}
			
		}
		
		.member_search_menu {
			display: -webkit-flex;
			display: flex;
			flex-wrap: wrap;
			
			@include mq() {
				display: block;
			}
			
			li {
				width: 44.4%;
				margin-right: calc(4% / 2);
				margin-bottom: 10px;
				text-indent: -1.8em;
				padding-left: 1.8em;
				
				&:nth-child(2n) {
					margin-right: 0;
				}
				
				@include mq() {
					width: 100%;
					margin-right: 0;
					margin-bottom: 5px;
				}
			}
		}
		
		.member_search_free_word {
			margin-top: 10px;
			
			input[type=text] {
				font-size: 1.6rem;
			}
		}
		
		.member_search_btn {
			margin-top: 30px;
			width: 100%;
			display: block;
			
			@include mq() {
				margin-top: 25px;
			}
			
			a, span {
				letter-spacing: 2rem;
			}
		}
	}
	
	
	/* 過年度ボックス */
	.member_past_years {
		margin-top: 20px;
		display: none;
		
		&.js-years_open {
			display: block;
		}
	}
	
	
	/* ログイン画面 */
	&.p-member_login {
		.form_success_ms {
			background: #e2f6eb;
			padding: 5px 10px;
			color: $green01;
			font-size: 1.4rem;
			margin-bottom: 15px;
		}
		
		.form_error_ms {
			background: #fff4f4;
			padding: 5px 10px;
			color: #E4011E;
			font-size: 1.4rem;
			margin-top: 10px;
		}
		
		/*.container {
			@include mq() {
				height: 100vh;
			}
		}*/
		
		.login_member_header {
			background: $blue01;
			color: #fff;
			text-align: center;
			padding: 25px 10px;
			
			@include mq() {
				padding: 15px 10px;
			}
			
			h1 {
				font-size: 2.5rem;
				line-height: 1.3;
				display: -webkit-flex;
				display: flex;
				justify-content: center;
				align-items: center;
				
				span {
					font-size: 3.5rem;
				}
				
				@include mq() {
					font-size: 1.8rem;
					display: block;
					
					span {
						display: block;
						font-size: 2.5rem;
					}
					
				}
				
			}
		}
		
		.member_login_logo {
			max-width: 226px;
			margin: 0 auto;
			
			@include mq() {
				max-width: 113px;
			}
			
		}
		
		.member_login_form__wrap {
			max-width: 1000px;
			margin: 75px auto 0;
			
			@include mq() {
				margin: 35px auto 0;
			}
			
		}
		
		.member_login_form__box01 {
			background: #fff;
			padding: 45px;
			border-radius: 30px;
			-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
			-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
			-ms-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
			
			@include mq() {
				padding: 25px;
			}
			
		}
		
		.member_login_form__box02 {
			max-width: 430px;
			margin: 25px auto 0;
			
			@include mq() {
				margin: 15px auto 0;
			}
			
		}
		
		.member_login_text01, .member_login_text02 {
			text-align: center;
			font-size: 2rem;
			
			@include mq() {
				font-size: 1.8rem;
			}
			
		}
		
		.member_login_text02 {
			margin-top: 35px;
			
			@include mq() {
				margin-top: 15px;
			}
			
		}
		
		.member_login_form__input_box {
			margin-top: 10px;
			
			&:first-of-type {
				margin-top: 0;
			}
		}
		
		.member_login_form__input {
			display: -webkit-flex;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			font-size: 2rem;
			margin-top: 10px;
			
			&:first-child {
				margin-top: 0;
			}
			
			@include mq() {
				font-size: 1.8rem;
			}
			
			dt {
				width: 5em;
				margin-right: 5px;
				flex-shrink: 0;
				line-height: 2;
			}
			
			dd {
				width: 100%;
			}
		}
		
		.member_login_form_btn {
			margin-top: 30px;
			display: -webkit-flex;
			display: flex;
			justify-content: center;
			
			@include mq() {
				margin-top: 15px;
			}
		}
		
		.member_login_password_caption {
			max-width: 650px;
			margin: 50px auto 0;
			max-width: 650px;
			border-top: 1px solid;
			padding-top: 20px;
			font-size: 1.8rem;
			text-align: center;
			
			@include mq() {
				margin: 25px auto 0;
				padding-top: 15px;
				font-size: 1.6rem;
			}
			
			.member_login_password_caption__title {
				font-size: 2rem;
				letter-spacing: 2px;
				line-height: 1.3;
				margin-bottom: 15px;
				
				@include mq() {
					font-size: 1.8rem;
					margin-bottom: 10px;
				}
				
			}
			
		}
		
		
	}
	
	/* パスワード変更画面 */
	&.p-password {
		.member_password_title {
			text-align: center;
			font-size: 2.5rem;
			line-height: 1.3;
			letter-spacing: 2px;
			margin-bottom: 35px;
			
			@include mq() {
				font-size: 2.3rem;
				margin-bottom: 20px;
			}
			
		}
		
		.member_password_box01 {
			max-width: 790px;
			margin: 0 auto;
		}
		
		.member_password__input_box {
			margin-top: 15px;
			
			&:first-of-type {
				margin-top: 0;
			}
		}
		
		.member_password__input {
			display: -webkit-flex;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			font-size: 2rem;
			margin-top: 10px;
			
			&:first-child {
				margin-top: 0;
			}
			
			@include mq() {
				display: block;
				font-size: 1.8rem;
			}
			
			dt {
				//width: 5em;
				width: 13em;
				margin-right: calc(10% / 2);
				flex-shrink: 0;
				line-height: 1.75;
				//line-height: 2;
			}
			
			dd {
				width: 100%;
			}
		}
		
		.member_password--check {
			font-size: 1.6rem;
			display: inline-block;
			margin-top: 5px;
		}
		
		.member_check_box {
			.member_check_box__ico {
				transform: translateY(-0.35em);
			}
		}
		
		.member_password_list {
			margin-top: 45px;
			
			@include mq() {
				margin-top: 15px;
			}
			
			li {
				position: relative;
				padding-left: .7em;
				
				&::before {
					content: "";
					display: block;
					width: .4em;
					height: .4em;
					background: #000;
					border-radius: 50%;
					position: absolute;
					left: 0;
					top: .65em;
				}
				
				&.member_password_list--red {
					color: $red01;
					
					&::before {
						background: $red01;
					}
					
				}
			}
		}
		
		.member_password_btn {
			margin-top: 35px;
			
			@include mq() {
				margin-top: 25px;
			}
			
			.member_btn01 {
				margin-top: 15px;
				
				&:first-of-type {
					margin-top: 0;
				}
			}
		}
		
	}
	
	
	/* 検索結果画面 */
	&.p-member_search {
		.footer {
			margin-top: 100px;
			
			@include mq() {
				margin-top: 50px;
			}
		}
		
		.member_doc_title {
			padding: 50px 10px;
			
			@include mq() {
				padding: 20px 10px;
			}
			
			.member_doc_title__text {
				margin-top: 0;
				padding-left: 0;
				text-align: center;
				
				&::before {
					content: none;
				}
				
				h2 {
					line-height: 1;
					margin-bottom: 0;
				}
				
			}
		}
		
		.member_search {
			margin-top: 40px;
			
			@include mq() {
				margin-top: 20px;
			}
		}
		
		.member_search_link {
			margin-top: 60px;
			
			@include mq() {
				margin-top: 30px;
			}
			
			.member_btn01 {
				a {
					background: $blue01;
				}
			}
		}
		
		.member_search_keyword--marker {
			background: #FFFF00;
		}
		
	}
	
	
	/* 検索一覧 */
	&.p-member_search_list {
		.member_search_result {
			margin-top: 100px;
			
			@include mq() {
				margin-top: 50px;
			}
		}
		
		.member_search_pager {
			margin-bottom: 40px;
			
			@include mq() {
				margin-bottom: 30px;
			}
			
			.member_search_pager__list {
				display: -webkit-flex;
				display: flex;
				justify-content: center;

				li {
					margin-right: calc(12% / 6);
					line-height: 1;

					&:last-child {
						margin-right: 0;
					}

					a {
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 2em;
						padding: 5px;
						width: 1.5em;
						height: 1.5em;
						transition: .3s all;

						@include pc_hover {
							background: $blue01;
							color: #fff; 
						}

					}

					&.is-current {
						a {
							border-radius: 2em;
							background: $blue01;
							color: #fff;
						}
					}
				}
			}
		}
		
		.member_search_result_date {
			color: #333;
			
			@include mq() {
				font-size: 1.4rem;
			}
			
		}
		
		.member_search_result_article {
			margin-top: 15px;
			
			@include mq() {
				margin-top: 10px;
			}
			
			.member_search_result_article__box01 {
				border-bottom: 1px solid $blue01;
				
				a {
					display: block;
					padding: 30px 15px;
					transition: opacity .3s;
					
					@include pc_hover {
						opacity: .7;
					}
					
					@include mq() {
						padding: 15px 5px;
					}
					
				}
				
				&:first-of-type {
					a {
						padding: 15px 15px 30px 15px;
						
						@include mq() {
							padding: 10px 5px 15px 5px;
						}
						
					}
				}
				
				.member_search_result_article__content {
					position: relative;
					//padding-right: 35px;
					
					/*&::after {
						content: "";
						display: block;
						background: url("../images/ico_pdf-red01.svg") no-repeat;
						width: 31px;
						height: 43px;
						position: absolute;
						top: 50%;
						right: 0;
						transform: translateY(-50%);
					}*/
					
					.member_search_result_article__title {
						position: relative;
						font-size: 2.1rem;
						font-weight: bold;
						line-height: 1.3;
						letter-spacing: 1px;
						color: $blue01;
						margin-bottom: 15px;
						
						.member_search_result_article__title--ico {
							display: inline-block;
						}
						
					}
				}
				
				
				&.member_search_result_article--pdf {
					
					.member_search_result_article__content {
						padding-right: 55px;
						
						&::after {
							content: "";
							display: block;
							background: url("../images/ico_pdf-red01.svg") no-repeat;
							width: 31px;
							height: 43px;
							position: absolute;
							top: 50%;
							right: 0;
							transform: translateY(-50%);
						}
						
						@include mq() {
							padding-right: 0;
							
							&::after {
								content: none;
							}
							
							.member_search_result_article__title {
								font-size: 1.9rem;
								margin-bottom: 10px;
								
								.member_search_result_article__title--ico {
									position: relative;
									padding-right: 30px;
									
									&::after {
										content: "";
										display: block;
										background: url("../images/ico_pdf-red01.svg") no-repeat;
										width: 20px;
										height: 29px;
										position: absolute;
										top: 50%;
										right: 0;
										transform: translateY(-50%);
									}
								}
							}
							
						}
						
					}
				}
				
			}
			
			.member_search_result_article_year {
				font-size: 1.4rem;
				margin-top: 5px;
			}
		}
		
	}
}