/************************************************

	一般事業主行動計画

*************************************************/
.action_box {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 10px 70px;
	
	@include mq() {
		padding: 0 10px 35px;
	}
	
	&.no_box_bottom01 {
		padding: 0 10px 0;
	}
	
	h3 {
		font-size: 2.5rem;
		border-bottom: 1px solid;
		padding-left: 15px;
		color: #005B97;
		margin-bottom: 37px;
		
		@include mq() {
			padding-left: 10px;
			font-size: 2.1rem;
			margin-bottom: 15px;
		}
		
	}
	
	.action_main_txt {
		padding-left: 15px;
		
		@include mq() {
			padding-left: 0;
		}
	}
	
	
}

.action_sub_box {
	margin-top: 50px;
	padding-left: 15px;
	
	@include mq() {
		padding-left: 0;
	}
	
	h4 {
		font-size: 18px;
		padding: 0 10px;
		border-left: 9px double #00A0E2;
		color: #333;
		margin-bottom: 20px;
	}
	
	.action_sub_txt_box {
		margin-bottom: 25px;
		
		&:last-child {
			margin-bottom: 0;
		}
		
		.action_sub_txt_box_ttl01 {
			margin-top: 10px;
		}
		
	}
	
	.action_sub_txt_box_indent01 {
		p {
			position: relative;
			padding-left: 20px;
			
			&::before {
				content: "";
				position: absolute;
				width: 12px;
				height: 12px;
				background: #333;
				top: 13px;
				transform: translateY(-50%);
				left: 0;
			}
			
		}
	}
	
	.action_sub_list01 {
		padding-left: 20px;
		
		li {
			text-indent: -0.5em;
			padding-left: 0.5em;
		}
		
		&.action_sub_list01--no_list_type {
			li {
				text-indent: inherit;
				padding-left: 0;
			}
		}
		
	}
	
	.action_task_list {
		li {
			text-indent: -4em;
			padding-left: 65px;
		}
	}
	
	.action_step_list {
		li {
			position: relative;
			padding-left: 20px;
			
			&::before {
				content: "";
				position: absolute;
				width: 12px;
				height: 12px;
				background: #333;
				top: 13px;
				transform: translateY(-50%);
				left: 0;
			}
		}
	}
	
}

