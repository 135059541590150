/************************************************

	お問い合わせ

*************************************************/
.inquiry_top_cap {
	text-align: center;
	font-size: 2.5rem;
	margin-top: 20px;
	
	@include mq(1023) {
		font-size: 1.8rem;
		margin: 10px 10px 0 10px;
	}
	
	@include mq(320) {
		font-size: 1.7rem;
	}
	
	
}

