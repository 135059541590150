/************************************************

	事業エリア・事業所一覧

*************************************************/
.area_box {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 10px 70px;
	
	@include mq() {
		padding: 0 10px 35px;
	}
	
	h3 {
		font-size: 2.5rem;
		border-bottom: 1px solid;
		padding-left: 15px;
		color: #005B97;
		margin-bottom: 37px;
		
		@include mq() {
			padding-left: 10px;
			font-size: 2.1rem;
			margin-bottom: 15px;
		}
		
	}
}

.office_list_box {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 10px;
	
	h3 {
		font-size: 2.5rem;
		border-bottom: 1px solid;
		padding-left: 15px;
		color: #005B97;
		margin-bottom: 37px;
		
		@include mq() {
			padding-left: 10px;
			font-size: 2.1rem;
			margin-bottom: 15px;
		}
		
	}
	
	.office_list_table_box {
		/*@include mq() {
			overflow-x: scroll;
		}*/
	}
	
	.office_list_table_wrap {
		
		/*@include mq() {
			min-width: 1200px;
		}*/
		
		.office_list_table {
			width: 100%;
			
			tr {
				position: relative;
				background-size: 10px 1px;
				background-image: linear-gradient(to right, #005B97, #005B97 5px, transparent 5px, transparent 10px);
				background-repeat: repeat-x;
				background-position: left bottom;
				
				&:first-of-type {
					//border-top: 1px solid #00A0E2;
				}
				
				&:last-child {
					//border-bottom: 1px solid #00A0E2;
				}
				
				
				&:nth-child(odd) {
					background-color: #ebf8ff;
				}
				
			}
			
			th, td {
				padding: 10px;
				vertical-align: middle;
				box-sizing: border-box;
				
				@include mq() {
					display: block;
					width: 100%;
				}
				
			}
			
			th {
				text-align: left;
				background-size: 10px 1px;
				background-image: linear-gradient(to right, #005B97, #005B97 5px, transparent 5px, transparent 10px);
				background-repeat: repeat-x;
				background-position: left bottom;
				width: 185px;
				
				@include mq() {
					padding: 10px 10px 0;
					background: none;
				}
				
			}
			
			td {
								
				@include mq() {
					padding: 10px 10px 0;
					
					&:last-child {
						padding: 10px;
					}
					
				}
				
				
			}
			
			
			
		}
		
	}	
	
}