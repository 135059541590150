html {
	font-size: 62.5%;
}

body {
	font-family: $gothic;
	line-height: 1.75;
	font-size:1.6em;
	color: #000;
}

