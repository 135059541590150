/************************************************

	リンク

*************************************************/
.link_box {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 10px 70px;
	
	&:last-of-type {
		padding: 0 10px;
	}
	
	@include mq() {
		padding: 0 10px 35px;
	}
	
	h3 {
		font-size: 2.5rem;
		border-bottom: 1px solid;
		padding-left: 15px;
		color: #005B97;
		margin-bottom: 37px;
		
		@include mq() {
			padding-left: 10px;
			font-size: 2.1rem;
			margin-bottom: 15px;
		}
		
	}
}

.link_list_area {
	li {
		margin-bottom: 10px;
		text-indent: -0.5em;
		padding-left: 14px;
		
		&::before {
			content: "";
			display: inline-block;
			position: relative;
			left: -5px;
			top: -3px;
			width: 7px;
			height: 7px;
			border-radius: 100%;
			background: #005B97;
		}
		
		a {
			position: relative;
			//background: url("../images/ico_ex_link.svg") no-repeat right center/17px;
			margin-right: 10px;
			
			@include pc_hover {
				text-decoration: underline;
			}
						
		}
		
		&:last-child {
			margin-bottom: 0;
		}
		
		#ico_link {
			position: relative;
			top: 1px;
		}
		
	}
}

