/************************************************

	研修・福利厚生

*************************************************/
.training_box {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 10px 70px;
	
	@include mq() {
		padding: 0 10px 35px;
	}
	
	&.no_box_bottom01 {
		padding: 0 10px 0;
	}
	
	&.human_resources_box {
		@include mq() {
			padding: 0 10px 20px;
		}
	}
	
	h3 {
		font-size: 2.5rem;
		border-bottom: 1px solid;
		padding-left: 15px;
		color: #005B97;
		margin-bottom: 37px;
		
		@include mq() {
			padding-left: 10px;
			font-size: 2.1rem;
			margin-bottom: 15px;
		}
		
	}
}

.training_sub_box {
	padding-left: 15px;
	
	@include mq() {
		padding-left: 0;
	}
	
	h4 {
		position: relative;
		//border-bottom: 3px solid #cce4ff;
		border-bottom: 3px solid;
		border-image: linear-gradient(to right, #C2EDFF 0%, #ffffff 100%);
		border-image-slice: 1;
		font-size: 2rem;
		margin-bottom: 20px;
		
		&::after {
			content: " ";
			display: block;
			position: absolute;
			border-bottom: solid 3px #00A0E2;
			bottom: -3px;
			width: 45px;
						
		}
		
	}
	
	.training_txt_box01 {
		margin-bottom: 25px;
	}
	
	.training_txt_box02 {
		margin-bottom: 20px;
		
		&:last-child {
			margin-bottom: 0;
		}
		
		h5 {
			font-size: 1.8rem;
			position: relative;
			padding-left: 18px;

			&::before {
				content: "";
				display: block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 7.5px 0 7.5px 13.0px;
				border-color: transparent transparent transparent #4CB5E6;
				position: absolute;
				top: 7px;
				//top: 50%;
				left: 0;
				//transform: translateY(-50%);
			}
		}
	}
	
	.training_img_box_wrap {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		
		.training_img_box {
			margin-right: calc(7% / 4);
			width: 23.6%;
			
			&:last-child {
				margin-right: 0;
			}
			
			@include mq() {
				margin-right: calc(7% / 2);
				width: 48%;
				margin-bottom: 15px;
				
				&:nth-child(2n) {
					margin-right: 0;
				}
				
				&:last-child {
					margin-right: inherit;
				}
				
			}
			
			.training_img_cap {
				margin-top: 4px;
				font-size: 1.4rem;
				
				@include mq() {
					margin-top: 2px;
					font-size: 1.2rem;
				}
				
			}
			
			
		}
		
	}
	
	
}
