/************************************************

	お問い合わせフォーム

*************************************************/
.inquiry_form {
	
	input[type=radio] {
		vertical-align: inherit;
		margin: 0 3px 0 0;
	}
	
	label[for] {
		cursor: pointer;
	}
	
	input[type="text"], textarea {
		padding: 6px;
		box-sizing: border-box;
		border: 1px solid #e6e6e6;
		font-size: 106%;
	}
	
	.w_full {
		width: 100%;
	}
		
	.w_half {
		//width: 50%;
		margin-right: 10px;
		
		@include mq() {
			width: calc(70% / 2);
		}
		
	}
	
	.w_25 {
		@include mq() {
			width: calc(85% / 4);
		}
	}
	
	.inquiry_form_error_ms {
		background: #fff4f4;
		padding: 5px 10px;
		color: #E4011E;
		font-size: 1.4rem;
		margin-top: 10px;
	}
	
	.inquiry_form_ico_new {
		display: inline-block;
		background: #E4011E;
		color: #fff;
		font-size: 1.3rem;
		line-height: 1;
		padding: 5px;
		vertical-align: inherit;
		margin-left: 5px;
		//margin-right: 5px;
		
		@include mq() {
			font-size: 1.2rem;
		}
		
	}
	
	.inquiry_form_wrap {
		max-width: 1200px;
		margin: 0 auto;
		padding: 0 10px;		
	}
	
	.inquiry_form_tit01 {
		font-size: 2.5rem;
		border-bottom: 1px solid;
		padding-left: 15px;
		color: #005B97;
		margin-bottom: 20px;
	}
	
	.inquiry_em_dyal_box {
		//border: 1px solid #E4011E;
		padding: 15px;
		text-align: center;
		background: #fff4f4;
		margin-bottom: 30px;
		
		.inquiry_em_dyal_tit {
			text-align: center;
			font-weight: bold;
			margin-bottom: 5px;
		}
		
	}
	
	.inquiry_em_dyal_box02 {
		border: 2px dashed #E4011E;
		padding: 15px;
		//text-align: center;
		background: #fff4f4;
		margin-bottom: 30px;
		
		.inquiry_em_dyal_box02_text {
			color: #E4011E;
		}
		
		.inquiry_em_dyal_tit {
			text-align: center;
			font-weight: bold;
			margin-bottom: 5px;
		}
		
	}
	
	.inquiry_txt_box {
		margin-bottom: 50px;
		
		h4 {
			font-size: 18px;
			padding: 0 10px;
			border-left: 9px double #00A0E2;
			color: #333;
			margin-bottom: 20px;
			
			@include mq() {
				margin-bottom: 10px;
			}
			
		}
		
		.inquiry_txt_link {
			margin: 15px 0;
			
			a {
				color: #005B97;
				font-weight: bold;
				position: relative;
				transition: .3s all;
				
				&::after {
					content: "＞";
					margin-left: 10px;
				}
				
				@include pc_hover {
					opacity: .7;
				}
				
			}			
			
		}
		
	}
	
	.inquiry_txt_list {
		margin-top: 15px;
		border: 1px solid #00A0E2;
		padding: 15px;
		
		ul {
			li {
				position: relative;
				text-indent: -0.5em;
				padding-left: 8px;
				margin-bottom: 5px;
				
				&:last-child {
					margin-bottom: 0;
				}
				
				&::before {
					content: "・";
					
				}
				
			}
			
			.inquiry_txt_red {
				color: #E4011E;
			}
			
		}
		
	}
	
	.inquiry_agree_box {
		border: 1px solid #E4011E;
		padding: 15px;
		
		ul {
			display: flex;
			justify-content: center;
			margin-top: 10px;
			
			li {
				margin-right: 40px;
				cursor: pointer;
				
				&:last-child {
					margin-right: 0;
				}
			}
			
		}
		
	}
	
	.inquiry_form_box {
		max-width: 1000px; 
		margin: 0 auto;
		
		table {
			width: 100%;
			
			@include mq() {
				margin-bottom: 10px;
			}
			
			
			th, td {
				padding: 15px 10px 20px;
				display: block;
				
				@include mq() {
					padding: 7px 10px 15px;
				}
				
			}
			
			th {
				text-align: left;
				vertical-align: top;
				padding: 15px 10px 0;
				
				.inquiry_form_required01 {
					background: #E4011E;
					color: #fff;
					font-size: 1.4rem;
					padding: 4px 5px;
					vertical-align: inherit;
					margin-left: 10px;
				}
				
				.inquiry_form_required02 {
					font-size: 1.4rem;
					margin-left: 10px;
					color: #E4011E;
				}
				
			}
			
			tr {
				border-bottom: 1px solid #e6e6e6;
				
				&:last-child {
					border-bottom: none;
				}
				
			}
			
		}
		
		.inquiry_form_list01 {
			display: flex;
			
			li {
				margin-right: 20px;
				
				&:last-child {
					margin-right: 0;
				}
				
			}
			
		}
		
		.inquiry_form_list02 {
			display: flex;
			flex-wrap: wrap;
			
			li {
				margin-right: 20px;
				
				&:last-child {
					margin-right: 0;
				}
				
			}
			
			@include mq() {
				display: block;
				
				li {
					margin-bottom: 10px;
					
					&:last-child {
						margin-bottom: 0;
					}
					
				}
				
			}
			
		}
		
		.inquiry_form_btn {
			display: flex;
			justify-content: center;
			margin-top: 30px;
			
			@include mq() {
				margin-top: 5px;
			}
			
			p {
				margin-right: 20px;
				
				&:last-child {
					margin-right: 0;
				}
				
			}
			
			.btn_inquiry_next {
				max-width: 205px;
				border: 1px solid $blue01;
				
				a {
					display: block;
					padding: 14px 60px;
					color: #fff;
					background: $blue01;
					transition: .3s all;
					
					@include pc_hover {
						background: #fff;
						color: $blue01;
					}
					
				}
			}
			
			.btn_inquiry_prev {
				max-width: 205px;
				border: 1px solid #a9a9a9;
				
				a {
					display: block;
					padding: 14px 60px;
					color: #fff;
					background: #a9a9a9;
					transition: .3s all;
					
					@include pc_hover {
						background: #fff;
						color: #a9a9a9;
					}
					
				}
			}
			
			
		}
		
	}
	
	.inquiry_comp_box {
		text-align: center;
		
		@include mq() {
			margin-bottom: 25px;
		}
		
		
		h4 {
			font-size: 2.1rem;
			margin-bottom: 15px;
			
			@include mq() {
				font-size: 2rem;
			}
			
		}
	}
	
	
	
	
}
