/************************************************

	商品詳細

*************************************************/
.product_detail {
	&.airkotarokomachi {
		.product_detail_spec {
			.product_detail_table02 {
				table {
					.product_detail_table_tit03 {
						border-right: 1px solid #000;
					}
				}
			}
			
			.product_detail_btn_area {
				.product_detail_btn_2col {
					margin-bottom: 15px;
				}
				
				.product_detail_btn_back {
					margin-top: 40px;
				}
				
			}
			
		}
	}
	
	&.blue-roof_light {
		.product_detail_thumb {
			@include mq() {
				margin-bottom: 15px;
			}
		}
		
		.product_detail_spec {
			.product_detail_table {
				margin-bottom: 15px;
			}
		}
	}
	
	&.p-everpanel {
		.product_detail_spec{
			.product_detail_table {
				margin-bottom: 40px;
				
				.product_detail_table_wrap {
					ul {
						margin-bottom: 20px;
					}
				} 
			}
		}  
	}
	
	&.p-ev {
		.product_detail_table {
			margin-bottom: 40px;
			
			.product_detail_table_wrap {
				ul {
					margin-bottom: 0;
				}
				
				.f_small {
					margin-bottom: 20px;
				}
				
			}
		}
	}
	
	&.p-fitting {
		.product_detail_spec {
			.product_detail_table02 {
				margin-bottom: 0;
			}
			
			ul {
				margin-bottom: 0;
			}
		}
	}
	
	&.p-tunnel {
		.product_detail_spec {
			.product_detail_table02 {
				margin-bottom: 15px;
			}
		}
	}
	
	&.p-slitguard {
		.product_detail_table {
			margin-bottom: 40px;
			
			.product_detail_table_wrap {
				ul {
					margin-bottom: 0;
				}
			}
		} 
	}
	
	&.p-safetystep {
		.product_detail_spec {
			ul {
				//margin-bottom: 40px;
			}
		}
	}
	
	&.p-mqcrossanchor {
		.product_detail_spec {
			ul {
				margin-bottom: 0;
			}
		} 
	}
	
	&.p-vroad {
		.product_detail_spec {
			ul {
				margin-bottom: 0;
			}
			
			.f_small {
				margin-bottom: 20px;
			}
			
		} 
	}
	
	&.p-cylinder {
		.product_detail_caption01 {
			margin-bottom: 20px;
		}
	}
	
	&.p-mm {
		.product_detail_spec {
			.product_detail_table {
				margin-bottom: 40px;
			}
			
			#mm_conditions {
				&.product_detail_table {
					//margin-bottom: 15px;
				}
			}
		}
	}
	
	&.p-rej {
		.product_detail_thumb {
			@include mq() {
				margin-bottom: 15px;
			}
		}
		
		.product_detail_thumb02 {
			margin-bottom: 35px;
			
			@include mq() {
				margin-bottom: 15px;
			}
		}
		
		.product_detail_spec {
			.product_detail_table {
				margin-bottom: 40px;
			}
			
			#rej_conditions {
				&.product_detail_table {
					//margin-bottom: 15px;
				}
			}
		}
	}
	
	&.p-jws {
		.product_detail_spec {
			.product_detail_table {
				margin-bottom: 40px;
			}
		}
		
		.product_detail_caption01 {
			margin-bottom: 20px;
		}
	}
	
	
}

.product_detail_wrap {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 10px;
}

.product_detail_tit {
	margin-bottom: 33px;
	
	@include mq() {
		margin-bottom: 17px;
	}
	
	p {
		color: #4D4D4D;
		font-size: 1.5rem;
	}
	
	.product_detail_tit--red {
		color: #E4011E;
		font-size: 1.6rem;
	}
	
	h3 {
		font-size: 3.3rem;
		color: #009944;
		
		@include mq() {
			font-size: 2.5rem;
		}
		
		span {
			font-size: 2.8rem;
		}
		
		
	}
}

.product_detail_caption {
	margin-bottom: 25px;
	
	span {
		font-size: 2.5rem;
		font-weight: bold;
		color: #009944;
		
		@include mq() {
			font-size: 2rem;
			line-height: 1;
		}
		
	}
}

.product_detail_thumb {
	margin-bottom: 5px;
	
	ul {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		
		@include mq() {
			display: block;
		}
		
		li {
			margin-right: calc(8% / 2);
			margin-bottom: 35px;
			width: 47.9%;
			
			&:nth-child(2n) {
				margin-right: 0;
			}
			
			&:last-child {
				margin-right: 0;
			}
			
			@include mq() {
				margin-right: 0;
				margin-bottom: 20px;
				width: 100%;
				
				&:last-child {
					margin-bottom: 0;
				}
				
			}
			
			&.pc_block {
				display: inline;
				
				@include mq() {
					display: none;
				}
				
			}
			
			&.sp_block {
				display: none;
				
				@include mq() {
					display: inline;
				}
				
			}
			
			
		}
		
	}

	.image_center_ul{
		ul {
			justify-content: center;
		}
	}
	
	&.product_detail_thumb_center {
		ul {
			li {
				width: 100%;
			}
		}
	}
	
	&.product_detail_thumb_center02 {
		ul {
			li {
				width: auto;
			}
		}
	}
	
}

.product_detail_thumb02 {
	margin-bottom: 50px;
	
	ul {
		display: flex;
		justify-content: space-between;
		
		@include mq() {
			display: block;
		}
		
		li {
			margin-right: 25px;
			
			&:last-child {
				margin-right: 0;
			}
			
			@include mq() {
				margin-right: 0;
				margin-bottom: 20px;
				
				&:last-child {
					margin-bottom: 0;
				}
				
			}
			
		}
		
	}

	.image_center_ul{
		ul {
			justify-content: center;
		}
	}
}

.product_detail_feature {
	margin-bottom: 40px;
	
	h3 {
		background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,153,68,1) 36%, rgba(0,153,68,1) 100%);
		background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,153,68,1) 36%, rgba(0,153,68,1) 100%);
		background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(0,153,68,1) 36%, rgba(0,153,68,1) 100%);
		padding: 5px 0 5px 10px;
		font-size: 2.5rem;
		color: #fff;
		margin-bottom: 20px;
		
		@include mq() {
			font-size: 2.1rem;
		}
		
	}
	
	ul {
		li {
			position: relative;
			padding-left: 20px;
			
			&::before {
				content: "";
				position: absolute;
				width: 12px;
				height: 12px;
				background: #333;
				top: 13px;
				transform: translateY(-50%);
				left: 0;
			}
		}
	}

	.product_detail_feature_tit_area{
		.product_detail_feature_tit01 {
			color: #000;
			text-align: center;
			display: inline-block;
 			padding: 0px 20px;
			font-weight: normal;
			border: 1px solid #4D4D4D;
		}
		.product_detail_feature_sub_text01{
			margin-bottom:15px;
		}
	}	
}

.product_detail_spec {
	margin-bottom: 90px;
	
	@include mq() {
		margin-bottom: 45px;
	}
	
	h3 {
		background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,153,68,1) 36%, rgba(0,153,68,1) 100%);
		background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,153,68,1) 36%, rgba(0,153,68,1) 100%);
		background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(0,153,68,1) 36%, rgba(0,153,68,1) 100%);
		padding: 5px 0 5px 10px;
		font-size: 2.5rem;
		color: #fff;
		margin-bottom: 20px;
		
		@include mq() {
			font-size: 2.1rem;
		}
		
	}
	
	ul {
		margin-bottom: 20px;
		
		
		li {
			position: relative;
			padding-left: 20px;
			
			&::before {
				content: "";
				position: absolute;
				width: 12px;
				height: 12px;
				background: #333;
				top: 13px;
				transform: translateY(-50%);
				left: 0;
			}
		}
	}
		
	.product_detail_table_indent {
		margin-left: 1.5em;
		
	}
	
	.product_detail_table {
		margin-bottom: 40px;
		//margin-bottom: 15px;
		
		@include mq() {
			overflow-x: scroll;
			
			.product_detail_table_wrap {
				min-width: 700px;
			}
			
		}
		
		.product_detail_table_wrap{
			ul{
				margin-top: 25px;
				//margin-bottom: -10px;
			}

			.table_wrap_text_right{
				text-align: right;
				//float: right;
				//position:relative;
			}
		}

		table {
			width: 100%;
			
			.product_detail_table_tit_area {
				border-bottom: none;
			}
			
			tr {
				border-bottom: 1px solid;
			}
			
			th, td {
				padding: 15px 0;
			}
			
			th {
				font-weight: normal;
				text-align: left;
				color: #009944;
				width: 425px;
				vertical-align: middle;
				padding: 15px;
				
				@include mq() {
					width: 140px;
				}
				
			}
			
			.product_detail_table_tit01 {
				color: #000;
				padding: 0;
				
				span {
					border: 1px solid #4D4D4D;
					padding: 5px 10px;
					width: 128px;
					display: block;
					text-align: center;
					line-height: 1;
				}
				
			}
									
			td {
				width: 380px;
				vertical-align: middle;
				
				@include mq() {
					width: 170px;
				}
				
			}

		}
	}
	
	.product_detail_table02 {
		margin-bottom: 40px;
		//margin-top: 15px;
		
		@include mq() {
			overflow-x: scroll;
			
			.product_detail_table_wrap {
				min-width: 700px;
			}
			
		}
		
		table {
			width: 100%;
			margin-bottom: 15px;
			
			.product_detail_table_tit_area {
				border-bottom: none;
			}
			
			tr {
				border-bottom: 1px solid;
			}
			
			th, td {
				padding: 15px 0;
			}
			
			th {
				font-weight: normal;
				text-align: left;
				color: #009944;
				width: 250px;
				vertical-align: middle;
				
				@include mq() {
					width: 140px;
				}
				
			}
			
			.product_detail_table_tit01 {
				color: #000;
				padding: 0;
				
				span {
					border: 1px solid #4D4D4D;
					padding: 5px 10px;
					width: 128px;
					display: block;
					text-align: center;
					line-height: 1;
				}
				
			}
			
			.product_detail_table_tit02 {
				color: #000;
				padding: 0;
				
				span {
					border: 1px solid #009944;
					padding: 10px;
					//width: 128px;
					display: block;
					text-align: center;
					line-height: 1;
					margin: 0 auto;
					//background: #009944;
					color: #009944;
					
				}
			}

			.product_detail_table_tit03 {
				font-weight: normal;
				text-align: left;
				color: #009944;
			}
			
			.product_detail_table_sub_tit01 {
				font-weight: normal;
				text-align: left;
				color: #009944;
				background: #D0F0DC;
				padding: 15px;
				border-left: 1px solid #000;
				//vertical-align: inherit;
				width: 100px;
			}
			
			td {
				//width: 380px;
				vertical-align: middle;
				padding: 15px;
				
				@include mq() {
					width: 170px;
				}
				
			}

			.product_detail_table_sub_tit02 {
				font-weight: normal;
				text-align: left;
				color: #ea5514;
				//background: #ea5514;
				padding: 15px;

				//vertical-align: inherit;
				width: 100px;
			}

		}
	}
	
	.product_detail_table03 {
		margin-bottom: 40px;
		//margin-bottom: 15px;
		
		@include mq() {
			overflow-x: scroll;
			
			.product_detail_table_wrap {
				min-width: 700px;
			}
			
		}
		
		table {
			width: 100%;
			margin-bottom: 15px;
			
			.product_detail_table_tit_area {
				border-bottom: none;
			}
			
			tr {
				border-bottom: 1px solid;
			}
			
			th, td {
				padding: 15px 0;
			}
			
			th {
				font-weight: normal;
				text-align: left;
				color: #009944;
				width: 381px;
				vertical-align: middle;
				
				@include mq() {
					width: 140px;
				}
				
			}
			
			.product_detail_table_tit01 {
				color: #000;
				padding: 0;
				
				span {
					border: 1px solid #4D4D4D;
					padding: 5px 10px;
					width: 128px;
					display: block;
					text-align: center;
					line-height: 1;
				}
				
			}
			
			.product_detail_table_tit02 {
				color: #000;
				padding: 0;
				
				span {
					border: 1px solid #009944;
					padding: 10px;
					//width: 128px;
					display: block;
					text-align: center;
					line-height: 1;
					margin: 0 auto;
					//background: #009944;
					color: #009944;
					
				}
			}

			.product_detail_table_tit03 {
				font-weight: normal;
				text-align: left;
				color: #009944;
			}
			
			.product_detail_table_sub_tit01 {
				font-weight: normal;
				text-align: left;
				color: #009944;
				background: #D0F0DC;
				padding: 15px;
				border-left: 1px solid #000;
				//vertical-align: inherit;
				width: 100px;
			}
			
			td {
				//width: 380px;
				vertical-align: middle;
				padding: 15px;
				
				@include mq() {
					width: 170px;
				}
				
			}

			.product_detail_table_sub_tit02 {
				font-weight: normal;
				text-align: left;
				color: #FFFFFF;
				background: #ea5514;
				padding: 15px;

				//vertical-align: inherit;
				width: 100px;
			}

		}
	}
	
	.product_detail_caption01 {
		margin-bottom: 40px;
	}
	
	.product_detail_spec_img {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-bottom: 20px;
		
		@include mq() {
			display: block;
		}
		
		.product_detail_spec_img_box {
			margin-bottom: 20px;
			margin-right: 20px;
			
			&:last-child {
				margin-right: 0;
			}
			
			p {
				margin-bottom: 10px;
			}
			
			span {
				font-size: 1.3rem;
			}
			
		}
		
	}
	
	.product_detail_spec_img_large {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-bottom: 20px;
		
		@include mq() {
			display: block;
		}
		
		.product_detail_spec_img_box {
			margin-bottom: 20px;
			margin-right: 20px;
			
			&:last-child {
				margin-right: 0;
			}
			
			p {
				margin-bottom: 10px;
			}
			
			span {
				font-size: 1.3rem;
			}
			
		}
	}
	
	.product_detail_spec_mov {
		margin-bottom: 40px;
	}
	
	.product_detail_btn_area {
		
		.product_detail_btn_2col {
			display: flex;
			justify-content: center;
			margin-bottom: 40px;
			
			@include mq() {
				display: block;
			}
			
			> div {
				margin-right: 30px;
				
				@include mq() {
					margin-right: auto;
				}
				
			}
			
			.product_detail_btn_pdf, .product_detail_btn_results, .product_detail_btn_manual {
				background: #009944;
				border-radius: 8px;
				font-size: 2.3rem;
				color: #fff;
				max-width: 275px;
				width: 100%;
				text-align: center;
				transition: .3s;
					
				&:hover {
					opacity: .7;
				}
				
				@include mq() {
					max-width: 80%;
					margin: 0 auto;
					font-size: 2.1rem;
					
					&:hover {
						opacity: 1;
					}
				}
				
				a {
					display: block;
					padding: 10px;
					position: relative;
					
					&::after {
						content: "";
						position: absolute;
						background: url("../images/ico_pdf01.png") no-repeat right center/28px;
						width: 28px;
						padding-bottom: 37px;
						right: 10px;
					}
					
				}
				
			}
			
			.product_detail_btn_pdf {
				margin-right: 30px;
				
				@include mq() {
					margin-right: auto;
					margin-bottom: 15px;
				}
				
			}
			
		}
		
		.product_detail_btn_back {
			background: #009944;
			color: #fff;
			max-width: 650px;
			font-size: 2.8rem;
			margin: 0 auto;
			text-align: center;
			transition: .3s;
					
			&:hover {
				opacity: .7;
			}
			
			@include mq() {
				font-size: 2.5rem;
				
				&:hover {
					opacity: 1;
				}
				
			}
			
			a {
				display: block;
				padding: 15px;
				position: relative;
				
				
				&::after {
					content: "";
					position: absolute;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 14px 24.2px 14px 0;
					border-color: transparent #ffffff transparent transparent;
					top: 50%;
					transform: translateY(-50%);
					left: 15px;
				}
				
				@include mq() {
					padding: 10px;
					
					
					&::after {
						border-width: 12.5px 21.7px 12.5px 0;
					}
				}
				
			}
			
		}
	}
}

.product_detail_inquiry {
	.product_detail_inquiry_tit {
		text-align: center;
		font-size: 2.8rem;
		color: #4D4D4D;
		
		@include mq() {
			font-size: 2.5rem;
		}
		
	}
	
	.product_detail_inquiry_box01 {
		margin: 60px auto 0;
		
		@include mq(1075) {
			margin: 30px auto 0;
		}
		
	}
	
	.inquiry_2col_box {
		max-width: 1300px;
		margin: 60px auto 0;
		display: flex;
		justify-content: space-between;

		@include mq(1075) {
			display: block;
			margin: 30px auto 0;
		}


		.inquiry_tell_box {
			margin-right: 15px;
			max-width: 570px;
			width: 100%;

			@include mq(1075) {
				max-width: inherit;
				margin-bottom: 30px;
			}

			h3 {
				position: relative;
				background-image: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
				background-image: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
				background-image: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
				color: #fff;
				padding: 10px 0 10px 20px;
				font-size: 2rem;
				font-weight: normal;

				span {
					padding-left: 65px;
					display: block;

					&::before {
						content: "";
						display: block;
						background: url("../images/ico_tell.png") no-repeat left center/43px;
						padding-bottom: 40px;
						position: absolute;
						left: 20px;
						top: 50%;
						transform: translateY(-50%);
						width: 43px;
					}

					@include mq() {
						padding-left: 33px;
						font-size: 1.7rem;

						&::before {
							background: url("../images/ico_tell.png") no-repeat left center/22px;
							left: 15px;
						}
					}


				}

				@include mq(1023) {
					padding: 10px 0 10px 15px;
				}


			}

			.inquiry_num_box {
				font-size: 2.5rem;
				/*font-size: 3.8rem;*/
				font-weight: bold;
				color: #005B97;
				padding: 20px 25px;
				line-height: 1;

				p {
					margin-bottom: 10px;
					//max-width: 320px;
					max-width: 330px;
					text-align: center;

					&:last-child {
						margin-bottom: 0;
					}
					
					@include mq() {
						max-width: inherit;
						text-align: left;
					}
					
					
				}
				
				.inquiry_department {
					font-size: 1.8rem;
				}
				
				span {
					padding-left: 20px;
				}

				@include mq(1075) {
					padding: 10px 12px;
				}

				@include mq() {
					font-size: 2rem;

					span {
						font-size: 3rem;
						padding-left: 10px;
					}

				}


			}

		}

		.inquiry_mail_box {
			max-width: 570px;
			width: 100%;

			@include mq(1075) {
				max-width: inherit;
			}

			h3 {
				position: relative;
				background-image: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
				background-image: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
				background-image: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
				color: #fff;
				padding: 10px 0 10px 20px;
				font-size: 2rem;
				font-weight: normal;

				span {
					padding-left: 65px;
					display: block;

					&::before {
						content: "";
						display: block;
						background: url("../images/ico_mail.png") no-repeat left center/47px;
						padding-bottom: 40px;
						position: absolute;
						left: 20px;
						top: 50%;
						transform: translateY(-50%);
						width: 47px;
					}

					@include mq() {
						padding-left: 33px;
						font-size: 1.7rem;

						&::before {
							background: url("../images/ico_mail.png") no-repeat left center/22px;
							left: 15px;
						}

					}


				}

				@include mq(1023) {
					padding: 10px 0 10px 15px;
				}

			}

			.inquiry_form_box {
				padding: 20px 25px;
				color: #005B97;
				line-height: 1;

				p {
					font-size: 2.1rem;
					font-weight: bold;
					margin-bottom: 10px;

					&:last-child {
						margin-bottom: 0;
					}

				}

				.inquiry_form_box_link {
					margin-bottom: 0;

					a {
						display: block;
						text-decoration: underline;

						span {
							position: relative;

							&::after {
								content: "";
							}

						}
												
					}

				}

				.inquiry_form_box_cap {
					display: inline-block;
					text-indent: -1em;
					padding-left: 15px;
					color: #333;
					line-height: 1.5em;
					margin-top: 5px;
					font-size: 1.5rem;
				}

				@include mq(1075) {
					padding: 10px 12px;

					p {
						font-size: 1.8rem;
					}

					.inquiry_form_box_cap {
						font-size: 1.3rem;
					}


				}

				@include mq(320) {
					p {
						font-size: 1.6rem;
					}
				}

			}

		}
	}
	
	.inquiry_tell_box02 {
		//max-width: 570px;
		max-width: 60%;
		width: 100%;
		margin: 0 auto;

		@include mq(1075) {
			max-width: inherit;
			margin-bottom: 30px;
		}

		h3 {
			position: relative;
			background-image: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
			background-image: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
			background-image: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
			color: #fff;
			padding: 10px 0 10px 20px;
			font-size: 2rem;
			font-weight: normal;

			span {
				padding-left: 65px;
				display: block;

				&::before {
					content: "";
					display: block;
					background: url("../images/ico_tell.png") no-repeat left center/43px;
					padding-bottom: 40px;
					position: absolute;
					left: 20px;
					top: 50%;
					transform: translateY(-50%);
					width: 43px;
				}

				@include mq() {
					padding-left: 33px;
					font-size: 1.7rem;

					&::before {
						background: url("../images/ico_tell.png") no-repeat left center/22px;
						left: 15px;
					}
				}


			}

			@include mq(1023) {
				padding: 10px 0 10px 15px;
			}


		}
		
		.inquiry_tell_sttl01 {
			font-size: 2.1rem;
			font-weight: bold;
			margin-bottom: 10px;
			
			@include mq() {
				font-size: 1.9rem;
			}
			
		}
		
		.inquiry_tell_col01 {
			display: -webkit-flex;
			display: flex;
			
			@include mq() {
				display: block;
			}
			
			> div {
				margin-right: calc(8% / 2);
				
				&:last-child {
					margin-right: 0;
				}
				
				@include mq() {
					margin-right: 0;
					margin-bottom: 10px;
					
					&:last-child {
						margin-bottom: 0;
					}
				}
				
			}
			
		}
		
		.inquiry_num_box {
			font-size: 2.5rem;
			/*font-size: 3.8rem;*/
			font-weight: bold;
			//color: #005B97;
			padding: 20px 25px;
			line-height: 1;
			
			/*p {
				margin-bottom: 10px;
				max-width: 320px;
				text-align: center;

				&:last-child {
					margin-bottom: 0;
				}

				@include mq() {
					max-width: inherit;
					text-align: left;
				}


			}*/
			
			.inquiry_tel_address {
				font-size: 2.2rem;
				line-height: 1.3;
				margin-bottom: 7px;
				
				@include mq() {
					font-size: 1.8rem;
				}
				
			}
			
			.inquiry_department {
				font-size: 1.8rem;
			}

			span {
				padding-left: 20px;
			}

			@include mq(1075) {
				padding: 10px 12px;
			}

			@include mq() {
				font-size: 2rem;

				span {
					font-size: 3rem;
					padding-left: 10px;
				}

			}


		}

	}
	
	.inquiry_mix_box {
		margin-top: 30px;
		width: 100%;
		

		@include mq(1075) {
			max-width: inherit;
		}

		h3 {
			position: relative;
			background-image: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
			background-image: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
			background-image: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
			color: #fff;
			padding: 10px 0 10px 20px;
			font-size: 2rem;
			font-weight: normal;

			span {
				//padding-left: 65px;
				padding-left: 115px;
				display: block;

				&::before {
					content: "";
					display: block;
					background: url("../images/ico_tell.png") no-repeat left center, url("../images/ico_mail.png") no-repeat 50px center;
					padding-bottom: 40px;
					position: absolute;
					left: 20px;
					top: 50%;
					transform: translateY(-50%);
					width: 98px;
				}
				
				
				
				@include mq() {
					//padding-left: 33px;
					padding-left: 63px;
					font-size: 1.7rem;

					&::before {
						background-size: 22px;
						background-position: left center, 30px center;
						//background: url("../images/ico_mail.png") no-repeat left center/22px;
						left: 15px;
						width: 52px;
					}

				}


			}

			@include mq(1023) {
				//padding: 10px 0 10px 15px;
				padding: 10px 30px 10px 15px;
			}

		}
		
		.inquiry_2col_box {
			margin-top: 0;
		}
		
		.inquiry_form_box {
			padding: 20px 25px;
			color: #005B97;
			line-height: 1;

			p {
				font-size: 2.1rem;
				font-weight: bold;
				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0;
				}

			}

			.inquiry_form_box_link {
				margin-bottom: 0;

				a {
					display: block;
					text-decoration: underline;

					span {
						position: relative;

						&::after {
							content: "";
						}

					}

				}

			}

			.inquiry_form_box_cap {
				display: inline-block;
				text-indent: -1em;
				padding-left: 15px;
				color: #333;
				line-height: 1.5em;
				margin-top: 5px;
				font-size: 1.5rem;
			}

			@include mq(1075) {
				padding: 10px 12px;

				p {
					font-size: 1.8rem;
				}

				.inquiry_form_box_cap {
					font-size: 1.3rem;
				}


			}

			@include mq(320) {
				p {
					font-size: 1.6rem;
				}
			}

		}
	}
	
	
}

/* 施工例リンク */
.product_detail_inquiry--mm_link {
	display: -webkit-flex;
	display: flex;
	justify-content: center;
	margin-top: 15px;
	
	p {
		a {
			color: $blue02;
			text-decoration: underline;
			
			@include pc_hover {
				text-decoration: none;
			}
		}
	}
}


/*見出しボタンの色変更（製品案内＞便利工具・工事機材）*/
.convenient_cate{
	.product_detail_tit {
		h3 {
			color: #E95377;
		}
	}

	.product_detail_caption {
		span {
			color: #E95377;
		}
	}

	.product_detail_feature {
		h3 {
			background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(233,83,119,1) 36%, rgba(233,83,119,1) 100%);
			background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(233,83,119,1) 36%, rgba(233,83,119,1) 100%);
			background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(233,83,119,1) 36%, rgba(233,83,119,1) 100%);	
		}
	}
	
	.product_detail_spec {
		h3 {
			background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(233,83,119,1) 36%, rgba(233,83,119,1) 100%);
			background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(233,83,119,1) 36%, rgba(233,83,119,1) 100%);
			background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(233,83,119,1) 36%, rgba(233,83,119,1) 100%);
		}

		.product_detail_table {
			table {
				th {
					color: #E95377;			
				}
			}		
		}
		
		.product_detail_btn_area {
		
			.product_detail_btn_2col {
				.product_detail_btn_pdf, .product_detail_btn_results, .product_detail_btn_manual {
					background: #E95377;
					a {
						&::after {
							background: url("../images/ico_pdf03.png") no-repeat right center/28px;
						}
					}
				}
			}
		
			.product_detail_btn_back {
			background: #E95377;
			}
		}
	}
}

/*見出しボタンの色変更（製品案内＞土木資材）*/
.civil_eng_cate{
	.product_detail_tit {
		h3 {
			color: #ea5514;
		}
	}

	.product_detail_caption {
		span {
			color: #ea5514;
		}
	}

	.product_detail_feature {
		h3 {
			background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(234,85,20,1) 36%, rgba(234,85,20,1) 100%);
			background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(234,85,20,1) 36%, rgba(234,85,20,1) 100%);
			background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(234,85,20,1) 36%, rgba(234,85,20,1) 100%);	
		}
	}
	
	.product_detail_spec {
		h3 {
			background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(234,85,20,1) 36%, rgba(234,85,20,1) 100%);
			background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(234,85,20,1) 36%, rgba(234,85,20,1) 100%);
			background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(234,85,20,1) 36%, rgba(234,85,20,1) 100%);
		}

		.product_detail_table {
			table {

				td{
					padding: 15px;
					text-align: center;
					border-left: 1px solid;
					
					
					
				}

				th {
					color: #ea5514;
					padding: 15px;
					//text-align: center;
				}

				.product_detail_table_tit01 {	
					padding: 0 5px 10px;
					
					&:first-child {
						padding: 0 5px 10px 0;
					}
					
					&:last-child {
						padding: 0 0 10px 5px;
					}
					
					span {
					    //margin: 0 auto;
						border: 1px solid #ea5514;
						//width: 100%;
						width: auto;						
					}
				}

				.product_detail_table_sub_text02{
					background-color: #fce9e1;
				}
			}
			
			.everpanel_table {
				th {
					text-align: center;
				}
			}
			
			.slitguard_table {
				margin-bottom: 100px;
				
				&:last-child {
					margin-bottom: 0;
				}
				
				th {
					text-align: left;
				}
				
				tr {
					.slitguard_size_cell {
						text-align: left;
					}
				}
				
			}
			
			.mqs_table {
				td {
					text-align: left;
				}
			}
			
		}
		
		.product_detail_btn_area {
		
			.product_detail_btn_2col {
				.product_detail_btn_pdf, .product_detail_btn_results {
					background: #ea5514;
					a {
						&::after {
							background: url("../images/ico_pdf02.png") no-repeat right center/28px;
						}
					}
				}
			}
		
			.product_detail_btn_back {
			background: #ea5514;
			}
		}
	}
}

/*見出しボタンの色変更（製品案内＞保全技術）*/
.maintenance_cate{
	.product_detail_tit {
		h3 {
			color: #00a0e9;
		}
	}

	.product_detail_caption {
		span {
			color: #00a0e9;
		}
	}

	.product_detail_feature {
		h3 {
			background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,160,233,1) 36%, rgba(0,160,233,1) 100%);
			background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,160,233,1) 36%, rgba(0,160,233,1) 100%);
			background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(0,160,233,1) 36%, rgba(0,160,233,1) 100%);	
		}
		.product_detail_feature_tit_area{
			.product_detail_feature_tit01 {
				color: #00a0e9;
				border: 1px solid #00a0e9;
			}
		}
	}
	
	.product_detail_spec {
		h3 {
			background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,160,233,1) 36%, rgba(0,160,233,1) 100%);
			background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,160,233,1) 36%, rgba(0,160,233,1) 100%);
			background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(0,160,233,1) 36%, rgba(0,160,233,1) 100%);
		}

		.product_detail_table {
			table {

				td{
					padding: 15px;
					text-align: center;
					border-left: 1px solid;
				}

				th {
					color: #00a0e9;
					padding: 15px;
				}

				.product_detail_table_tit01 {
					padding: 0 5px 10px;
					
					&:first-child {
						padding: 0 5px 10px 0;
					}
					
					&:last-child {
						padding: 0 0 10px 5px;
					}
					
					span {
						//margin: 0 auto;
						border: 1px solid #00a0e9;
						width: auto;
					}
				}
				.product_detail_table_sub_text02{
					background-color: #D9EDFB;
					text-align: left;
				}
			}
			
			.mm_table {
				td {
					text-align: center;
				}
			}
			
			.rej_table {
				td {
					text-align: left;
				}
			}
			
		}
		
		.product_detail_btn_area {
		
			.product_detail_btn_2col {
				.product_detail_btn_pdf, .product_detail_btn_results {
					background: #00a0e9;
					a {
						&::after {
							background: url("../images/ico_pdf04.png") no-repeat right center/28px;
						}
					}
				}
			}
		
			.product_detail_btn_back {
			background: #00a0e9;
			}
		}
	}
}

/*見出しボタンの色変更（製品案内＞土木資材＞EV金具リストカラー変更）*/

.product_detail_spec {
	table {
		.ev_type_a{
			.product_detail_table_tit01 {
				border-bottom: 1px solid #fff;

				span{
				color: #e53828;
				border: 1px solid #e53828;
				padding: 5px 10px 5px 0px!important;
				}
			}
		}
				
		.ev_type_b{
			.product_detail_table_tit01 {
				border-bottom: 1px solid #fff;
				span{
				color: #009ee8;
				border: 1px solid #009ee8!important;
				padding: 5px 10px 5px 0px!important;
				}
			}
		}
		.ev_type_c{
			.product_detail_table_tit01 {
				border-bottom: 1px solid #fff;
				span{
				color: #751581;
				border: 1px solid #751581!important;
				padding: 5px 10px 5px 0px!important;
				}
			}
		}
		
		.ev_type_a_body{
			th {
				color: #e53828!important;
				background: #fff1f0;
			}
			
			td {
				border-left: none !important;
			}
			
		}
		
		.ev_type_b_body{
			th {
				color: #009ee8!important;
				background: #f0faff;
			}
			
			td {
				border-left: none !important;
			}
			
		}

		.ev_type_c_body{
			th {
				color: #751581!important;
				background: #fef2ff;
			}
			
			td {
				border-left: none !important;
			}
			
		}
	}
}

