
/************************************************

	common

*************************************************/
a,
a:visited,
a:hover {
    color: inherit;
    text-decoration: none;
}

img {
    width: 100%;
    height: auto;
    vertical-align: top;
}

button {
	padding: 0;
	background: transparent;
	box-shadow: none;
	color: inherit;
	font-size: inherit;
	outline: none;
	border: none;
	cursor: pointer;
	font-weight: inherit;
}

.center {
	text-align: center !important;
}

.f_bold {
	font-weight: bold !important;
}

.f_small {
	font-size: 1.5rem !important;
}

.f_red {
	color: $red01;
}

.d_b {
	display: block !important;
}

.d_ib {
	display: inline-block !important;
}

.link_border {
	text-decoration: underline;
	
	@include pc_hover() {
		text-decoration: none;
	}
	
}

.notes {
	text-indent: -1rem;
	padding-left: 1rem;
}



/************************************************

	title

*************************************************/
.con_tit01 {
	font-size: 2.5rem;
    border-bottom: 1px solid;
    padding-left: 15px;
    color: #005B97;
    margin-bottom: 37px;
}

.con_tit02 {
	position: relative;
    border-bottom: 3px solid;
    border-image: linear-gradient(to right, #C2EDFF 0%, #ffffff 100%);
    border-image-slice: 1;
    font-size: 2rem;
    margin-bottom: 20px;
}

.con_tit03 {
	font-size: 1.8rem;
    padding: 0 10px;
    border-left: 9px double #00A0E2;
    color: #333;
    margin-bottom: 20px;
}

.con_tit04 {
	margin-bottom: 10px;
    padding: 10px 15px;
    border-left: 6px double #00A0E2;
    background: -webkit-linear-gradient(to right, #f2fbff, transparent);
    background: linear-gradient(to right, #f2fbff, transparent);
	
	&::after {
		content: " ";
		display: block;
		position: absolute;
		border-bottom: solid 3px #00A0E2;
		bottom: -3px;
		width: 45px;
	}
	
}

.con_sub_tit01 {
	font-size: 1.8rem;
    position: relative;
    padding-left: 18px;
	
	&::before {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 7.5px 0 7.5px 13.0px;
		border-color: transparent transparent transparent #4CB5E6;
		position: absolute;
		top: 7px;
		left: 0;
	}
	
}

.con_sub_tit02 {
	position: relative;
    font-size: 1.8rem;
    padding-left: 18px;
    margin-bottom: 10px;
	
	&::before {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 7.5px 0 7.5px 13.0px;
		border-color: transparent transparent transparent #00A0E2;
		position: absolute;
		top: 7px;
		left: 0;
	}
	
}

/************************************************

	side_menu

*************************************************/
.side_menu {
    width: 256px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    background: #fff;
    z-index: 10;
    
    @include mq($media_tb) {
        position: -webkit-sticky;
        position: sticky;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        width: 100%;
        height: auto;
        padding: 10px;
        box-sizing: border-box;
        
        @include mq_ie {
            position: fixed;
        }
    }
    
    .logo {
        max-width: 166px;
        padding: 0 10px;
        margin: 30px auto 0;
        transition: opacity .3s;
        
        @include pc_hover {
            opacity: .7;
        }
        
        @include mq($media_tb) {
            max-width: 90px;
            margin: 0;
            padding: 0;
        }
        
        @include mq(320) {
            max-width: 60px;
            margin: 0;
            padding: 0;
        }
    }
}

.gnav {
    
    @include mq($media_tb) {
        //visibility: hidden;
        position: fixed;
        width: 100%;
        height: 0;
        box-sizing: border-box;
        top: 0;
        left: 0;
        z-index: 11;
        transition: height .3s, border-color 0s .3s;
        overflow: hidden;
        background: #fff;
        border-bottom: 3px solid transparent;
    }
    
    .gnav_inner {
        
        @include mq($media_tb) {
            padding: 20px;
        }
    }

    &.opened {

        @include mq($media_tb) {
            height: 100vh;
            border-bottom: 3px solid $blue01;
            transition: height .3s, border-color 0s 0s;
        }
    }
    
    ul {
        
        @include mq($media_tb) {
            margin-top: 20px;
        }
    }
    
    li {
        text-align: center;
        margin-top: 60px;
        font-size: 2rem;
        color: #5d5d5d;
        
        @media screen and (max-height: 800px) {
            margin-top: 40px;
        }
        
        @media screen and (max-height: 700px) {
            margin-top: 20px;
        }
        
        @include mq($media_tb) {
            border-bottom: 1px solid #888;
            margin-top: 0;
            font-size: 1.6rem;
            line-height: 1.3;
            text-align: left;
        }
        
        &:first-of-type {
            
            @include mq($media_tb) {
                border-top: 1px solid #888;
            }
        }
        
        a {
            position: relative;
            display: inline-block;
            transition: color .3s;
            
            @include pc_hover {
                color: $blue01;
                
                &::after {
                    transform: scaleX(1);
                }
            }
            
            @include mq($media_tb) {
                display: block;
                padding: 15px 0;
                background: url("../images/ico_arrow05.svg") no-repeat right 5px center / 10px 15px;
            }
            
            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 2px;
                background: $blue01;
                left: 0;
                bottom: 0;
                transform-origin: left;
                transform: scaleX(0);
                transition: transform .3s;
            }
        }
    }
}

.menu_btn_contact {
    font-size: 2rem;
    line-height: 1.3;
    text-align: center;
    margin-top: 50px;
    
    @media screen and (max-height: 800px) {
        margin-top: 40px;
    }
    
    @media screen and (max-height: 700px) {
        margin-top: 20px;
    }
    
    @include mq($media_tb) {
        font-size: 1.2rem;
        margin-top: 0;
        margin-left: auto;
    }
    
    a {
        display: inline-block;
        color: $blue01;
        //background: #fff;
        border: 1px solid $blue01;
        padding: 10px 30px;
        box-sizing: border-box;
        transition: color .3s, background .3s;
        position: relative;
        overflow: hidden;
        
        @include mq($media_tb) {
            padding: 10px;
        }
        
        @include pc_hover {
            color: #fff;
            //background: $blue01;
            
            &::before {
                transform: scaleY(1);
            }
        }
        
        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background: $blue01;
            position: absolute;
            left: 0;
            bottom: 0;
            transition: transform .3s;
            transform-origin: bottom;
            //transform: rotateX(90deg);
            transform: scaleY(0);
            z-index: 0;
        }
        
        span {
            position: relative;
            z-index: 1;
        }
    }
}

.menu_btn_staff {
    margin-top: 50px;
    text-align: center;
    
    @media screen and (max-height: 800px) {
        margin-top: 40px;
    }
    
    @media screen and (max-height: 700px) {
        margin-top: 20px;
    }
    
    @include mq($media_tb) {
        margin-top: 0;
        margin-left: 15px;
        text-align: left;
        
        @media (hover: hover) {
            transform: translateY(-3px);
        }
    }
    
    a {
        display: inline-block;
        box-sizing: border-box;
        color: #5d5d5d;
        box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .5);
        transition: opacity .3s;
        
        @include pc_hover {
           opacity: .7;
        }
    }
    
    .btn_staff_label {
        font-size: 1.8rem;
        line-height: 1.3;
        padding: 10px 0;
        background: rgb(199,199,199);
        background: -moz-linear-gradient(top, rgba(199,199,199,1) 0%, rgba(255,255,255,1) 41%, rgba(255,255,255,1) 60%, rgba(255,255,255,1) 60%, rgba(210,210,210,1) 100%);
        background: -webkit-linear-gradient(top, rgba(199,199,199,1) 0%,rgba(255,255,255,1) 41%,rgba(255,255,255,1) 60%,rgba(255,255,255,1) 60%,rgba(210,210,210,1) 100%);
        background: linear-gradient(to bottom, rgba(199,199,199,1) 0%,rgba(255,255,255,1) 41%,rgba(255,255,255,1) 60%,rgba(255,255,255,1) 60%,rgba(210,210,210,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c7c7c7', endColorstr='#d2d2d2',GradientType=0 );
        
        @include mq($media_tb) {
            font-size: 1.2rem;
            padding: 10px 5px;
        }
        
        .ico_lock {
            background: url("../images/ico_lock.svg") no-repeat left center / 17px 17px;
            padding-left: 22px;
            
            @include mq($media_tb) {
                padding: 0px 0 0px 22px;
                display: inline-block;
            }
        }
    }
    
    .btn_lower {
        padding: 0 10px 10px;
        background: #d2d2d2;
        
        @include mq($media_tb) {
            display: none;
        }
    }
    
    .btn_staff_text {
        font-size: 1.5rem;
        line-height: 1.5;
        background: #fff;
        padding: 5px;
    }
}

.btn_sp_menu {
    display: none;
    width: 50px;
    height: 44px;
    position: relative;
    margin-left: 15px;

    @include mq($media_tb) {
        display: block;
    }
    
    .ico {
        display: block;
        width: 40px;
        height: 28px;
        margin: 0 auto;
        position: relative;
    }

    .bar {
        width: 100%;
        height: 3px;
        border-radius: 1px;
        background: $blue01;
        display: block;
        position: absolute;
        left: 0;
        
        &:nth-of-type(1) {
            top: 0;
        }
        
        &:nth-of-type(2) {
            top: 50%;
            transform: translateY(-1px);
        }
        
        &:nth-of-type(3) {
            bottom: 0;
        }
    }
    
    .text {
        font-size: 1.2rem;
        width: 100%;
        display: block;
        margin-top: 2px;
        color: $blue01;
        font-weight: bold;
    }
}

.btn_close_menu {
    text-align: right;
    display: none;
    
    @include mq($media_tb) {
        display: block;
    }
    
    button {
        //color: #fff;
    }
    
    .ico_close {
        position: relative;
        display: block;
        padding-left: 25px;
        
        &::before,
        &::after {
            content: '';
            display: block;
            width: 20px;
            height: 2px;
            background: #333;
            position: absolute;
            left: 0;
            top: 50%;
        }
        
        &::before {
            transform: translateY(-50%) rotate(45deg);
        }
        
        &::after {
            transform: translateY(-50%) rotate(-45deg);
        }
    }
}

/************************************************

	contants

*************************************************/
.container {
    position: relative;
}

.main {
    margin-left: 256px;
    
    @include mq($media_tb) {
        margin-left: 0;
        
        @include mq_ie {
            margin-top: 80px;
        }
    }
}

/*.con_wrap {
	margin-top: 50px;
	padding: 0 20px;
}*/


/************************************************

	footer

*************************************************/
.footer {
    background: $gray01;
    margin-top: 160px;
    
    @include mq($media_tb) {
        margin-top: 50px;
    }
    
    .footer_inner {
        padding: 50px 20px 90px;
        box-sizing: border-box;
        max-width: 1270px;
        margin: 0 auto;
		
		@include mq() {
			padding: 25px 20px;
		}
		
    }
    
    .footer_sitemap_block {
        display: -webkit-flex;
        display: flex;
        
        @include mq(767) {
            display: block;
        }
        
        > * {
            width: 25%;
            box-sizing: border-box;
            padding-right: 30px;
			
			@include mq() {
				width: 100%;
				padding-right: 0;
			}
			
        }
        
		#footer_sitemap01, #footer_sitemap02, #footer_sitemap03, #footer_sitemap_contact {
			@include mq() {
				display: none;
			}
		}
		
        .sitemap_list {
            
            li {
                padding-left: 20px;
                
                &:not(:first-child) {
                    margin-top: 5px;
                }
                
                a {
                    display: inline-block;
                    font-size: 1.6rem;
                    position: relative;
                    text-indent: -17px;
                    padding-left: 17px;
                    transition: opacity .3s;
                    
                    @include pc_hover {
                        opacity: .7;
                    }
                    
                    &::before {
                        content: '';
                        display: inline-block;
                        background: url("../images/ico_arrow05.svg") no-repeat left center/ 7px 11px;
                        width: 7px;
                        height: 11px;
                        margin-right: 10px;
                    }
					
					@include mq() {
						text-indent: -14px;
						padding-left: 14px;
					}
					
					
                }
                
                &.sitemap_ttl {
                    padding-left: 0;
                    
                    &:not(:first-of-type) {
                        margin-top: 10px;
                    }
                    
                    a {
                        font-size: 1.9rem;
                        font-weight: bold;
                        
                        &::before {
                            background: none;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 9px 0 9px 9px;
                            border-color: transparent transparent transparent #000000;
                            transform: translateY(1px);
                        }
						
						@include mq() {
							font-size: 1.6rem;
							display: block;
							
							&::before {
								border-width: 7px 0 7px 7px;
							}
							
						}
						
						
                    }
                }
            }
            
            & + .sitemap_list {
                margin-top: 20px;
            }
        }
    }
    
    .copy_right {
        padding: 10px 15px;
        background: $blue01;
        font-size: 1.6rem;
        line-height: 1.3;
        text-align: center;
        color: #fff;
        
        @include mq() {
            font-size: 1.2rem;
        }
    }
}

/************************************************

	btn

*************************************************/
.c_btn01 {
    
}

/************************************************

	utility

*************************************************/
.pc_br {
	display: block;
	
	@include mq() {
		display: none;
	}
}

.sp_br {
    display: none;
    
    @include mq() {
        display: inline-block;
    }
}

.sp_br02 {
    display: none;
    
    @include mq(320) {
        display: inline-block;
    }
}

.sp_br03 {
    display: none;
    
    @include mq() {
        display: inline-block;
    }
	
	@include mq(375) {
		display: none;
	}
	
}

.sp_br04 {
    display: none;
    
    @include mq() {
        display: inline-block;
    }
	
	@include mq(375) {
		display: none;
	}
	
}

.sp_br05 {
	display: none;
	
	@include mq() {
		display: none;
	}
	
	@include mq(320) {
		display: block;
	}
	
}

.sp_br06 {
	display: none;
	
	@include mq() {
		display: none;
	}
	
	@include mq(375) {
		display: block;
	}
	
	@include mq(320) {
		display: none;
	}
	
}

.sp_br07 {
	display: none;
	
	@include mq() {
		display: block;
	}
}

.sp_br08 {
	display: none;
	
	@include mq(430) {
		display: block;
	}
}

.d_ib {
    display: inline-block;
}

/************************************************

	con_head

*************************************************/
.con_head {
	background: url("../images/con_head06.jpg") no-repeat center top/cover;
	position: relative;
	
	.con_head_tit {
		position: relative;
		background-image: -moz-linear-gradient(25deg, #005b97 50%, transparent 50.1%);
		background-image: -webkit-linear-gradient(25deg, #005b97 50%, transparent 50.1%);
		background-image: linear-gradient(115deg, #005b97 50%, transparent 50.1%);
		mix-blend-mode: multiply;
		text-align: center;
		padding: 15% 0 10.5%;

		@include mq() {
			padding: 0 0 35%;
			background-image: -moz-linear-gradient(115deg, #005b97 55%, transparent 55.2%);
			background-image: -webkit-linear-gradient(115deg, #005b97 55%, transparent 55.2%);
			background-image: linear-gradient(115deg, #005b97 55%, transparent 55.2%);
		}
		
		@include mq_ie() {
			opacity: .7;
		}
		
	}
	
	.con_head_txt {
		position: absolute;
		top: 50%;
		left: 10%;
		transform: translateY(-50%);
		//text-align: center;
		text-align: left;

		@include mq() {
			text-align: left;
			left: 0;
			margin-left: 10px;
		}


		h2 {
			max-width: 332px;
			margin: 0 auto 10px;

			@include mq() {
				max-width: 50%;
				margin: 0 0 2%;
			}

		}

		p {
			//font-size: 3rem;
			font-size: 6rem;
			font-weight: bold;
			line-height: 1.3;
			color: #fff;

			@include mq() {
				font-size: 2.8rem;
				//font-size: 1.6rem;
			}

		}
	}
	
	&.con_head_product {
		background: url("../images/con_head02.jpg") no-repeat center top/cover;
		
		.con_head_txt {
			h2 {
				max-width: 286px;
				margin: 0 auto 10px;

				@include mq() {
					max-width: 50%;
					margin: 0;
				}

			}
		}
		
	}
	
	&.con_head_company {
		background: url("../images/con_head03.jpg") no-repeat center top/cover;
		
		.con_head_txt {
			h2 {
				max-width: 345px;
				margin: 0 auto 10px;

				@include mq() {
					max-width: 50%;
					margin: 0;
				}

			}
		}
		
	}
	
	&.con_head_csr {
		background: url("../images/con_head04.jpg") no-repeat center top/cover;
		
		.con_head_txt {
			h2 {
				max-width: 148px;
				margin: 0 auto 10px;

				@include mq() {
					max-width: 50%;
					margin: 0;
				}

			}
			
			p {
				font-size: 3rem;
				font-weight: normal;
				
				@include mq() {
					font-size: 1.6rem;
				}
				
			}
			
		}
		
	}
	
	
	&.con_head_inquiry {
		background: url("../images/con_head05.jpg") no-repeat center top/cover;
		
		.con_head_txt {
			h2 {
				max-width: 256px;
				margin: 0 auto 10px;

				@include mq() {
					max-width: 50%;
					margin: 0;
				}

			}
		}
		
	}
	
	&.con_head_news {
		background: url("../images/con_head02.jpg") no-repeat center top/cover;
		
		.con_head_txt {
			h2 {
				max-width: 199px;
				margin: 0 auto 10px;

				@include mq() {
					max-width: 50%;
					margin: 0;
				}

			}
		}
		
	}
	
	&.con_head_applicant {
		background: url("../images/con_head02.jpg") no-repeat center top/cover;
		
		.con_head_txt {
			h2 {
				max-width: 368px;
				margin: 0 auto 10px;

				@include mq() {
					max-width: 50%;
					margin: 0;
				}
			}
			
			p {
				font-size: 6.2rem;
				//font-size: 3rem;
				//font-weight: normal;
				
				@include mq() {
					font-size: 2.8rem;
					//font-size: 1.6rem;
				}
				
			}
			
		}
		
	}
	
}

/************************************************

	breadcrumb

*************************************************/
.breadcrumb {
	display: flex;
    align-items: center;
    margin: 0 30px;
    padding: 20px 0;
	
	@include mq(1023) {
		margin: 0 10px;
		padding: 15px 0;
		font-size: 1.4rem;
	}
	
	li {
		display: inline;
		
		&::after {
			content: "＞";
			padding: 0 10px;
			
			@include mq(320) {
				padding: 0 7px;
			}
			
		}
		
		&:last-child {
			&::after {
				content: "";
			}
		}
		
		a {
			transition: .3s;
			
			&:hover {
				opacity: .7;
			}
			
		}
		
		
	}
	
	
}

/************************************************

	colmn

*************************************************/
.con_2col {
	display: flex;
	align-items: center;
	flex-flow: row-reverse;
	justify-content: flex-end;
	margin-bottom: 160px;
	
	&:last-child {
		margin-bottom: 0;
	}
	
	@include mq(1023) {
		display: block;
		margin-bottom: 80px;
	}
	
	.con_2col_txt_box {
		max-width: 650px;
		width: 100%;
		
		@include mq(1023) {
			margin-bottom: 20px;
		}
		
		h3 {
			position: relative;
			font-size: 3rem;
			color: #005b97;
			padding-bottom: 10px;
			padding-left: 30px;
			margin-bottom: 30px;
			/*margin-top: 20px;*/

			&::after {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 7px;
				content: '';
				background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 51%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
				background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 51%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
				background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 51%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
			}
			
			@include mq(1023) {
				font-size: 2.1rem;
				padding-left: 0;
				margin-bottom: 15px;
			}
			
			@include mq(320) {
				font-size: 2rem;
			}
			
		}
		
		.con_2col_txt {
			font-size: 1.8rem;
			padding-left: 30px;
			
			@include mq(1023) {
				padding-left: 0;
				font-size: 1.6rem;
			}
			
		}
		
	}
	
	&.con_2col_reverse {
		justify-content: flex-end;
		flex-flow: nowrap;
		
		.con_2col_txt_box {
			max-width: 650px;

			h3 {
				position: relative;
				font-size: 3rem;
				color: #005b97;
				padding-bottom: 10px;
				padding-right: 30px;
				margin-bottom: 30px;
				/*margin-top: 20px;*/

				&::after {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 7px;
					content: '';
					background: -moz-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 51%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
					background: -webkit-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 51%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
					background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 51%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
					
					@include mq(1023) {
						background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 51%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
						background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 51%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
						background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 51%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
					}
					
				}
				
				@include mq(1023) {
					font-size: 2.1rem;
					padding-right: 0;
					margin-bottom: 15px;
				}
				
				@include mq(320) {
					font-size: 2rem;
				}
				
			}

			.con_2col_txt {
				padding-right: 30px;
				
				@include mq(1023) {
					padding-right: 0;
					font-size: 1.6rem;
				}
				
			}

		}
		
		
	}
}

.con_2col02 {
	display: flex;
	align-items: center;
	flex-flow: row-reverse;
	justify-content: flex-end;
	margin-bottom: 160px;
	
	&:last-child {
		margin-bottom: 0;
	}
	
	@include mq(1023) {
		display: block;
		margin-bottom: 80px;
	}
	
	.con_2col_txt_box {
		max-width: 650px;
		width: 100%;
		
		@include mq(1023) {
			margin-bottom: 20px;
		}
		
		h3 {
			position: relative;
			font-size: 3rem;
			color: #005b97;
			padding-bottom: 10px;
			margin-bottom: 30px;
			text-align: center;

			&::after {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 7px;
				content: '';
				background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 51%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
				background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 51%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
				background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 51%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
			}
			
			@include mq(1023) {
				font-size: 2.1rem;
				padding-left: 0;
				margin-bottom: 15px;
				text-align: left;
			}
			
			@include mq(320) {
				font-size: 2rem;
			}
			
			a {
				display: block;
				
				@include mq(1023) {
					padding-left: 0;
				}
				
				span {
					position: relative;

					&::after {
						content: "▶";
						display: block;
						position: absolute;
						top: 47%;
						right: -30px;
						transform: translateY(-47%);
						transition: .3s all;

						@include mq(1023) {
							right: -20px;
						}

					}
				}
				
				&:hover {
					span {
						&::after {
							right: -35px;
							
							@include mq(1023) {
								right: -20px;
							}
							
						}
					}
				}
				
			}
		}
		
		.con_2col_txt {
			font-size: 1.8rem;
			padding-left: 30px;
			
			@include mq(1023) {
				padding-left: 0;
				font-size: 1.6rem;
				text-align: left;
			}
			
		}
		
	}
	
	&.con_2col_reverse {
		justify-content: flex-end;
		flex-flow: nowrap;
		
		.con_2col_txt_box {
			max-width: 650px;

			h3 {
				position: relative;
				font-size: 3rem;
				color: #005b97;
				padding-bottom: 10px;
				margin-bottom: 30px;

				&::after {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 7px;
					content: '';
					background: -moz-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 51%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
					background: -webkit-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 51%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
					background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 51%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
					
					@include mq(1023) {
						background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 51%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
						background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 51%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
						background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 51%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
					}
					
				}
				
				@include mq(1023) {
					font-size: 2.1rem;
					padding-right: 0;
					margin-bottom: 15px;
				}
				
				@include mq(320) {
					font-size: 2rem;
				}
				
				a {
					display: block;
				}
				
			}

			.con_2col_txt {
				padding-right: 30px;
				text-align: right;
				
				@include mq(1023) {
					padding-right: 0;
					font-size: 1.6rem;
					text-align: left;;
				}
				
			}

		}
		
		
	}
	
	&.con_corp_2col {
		display: inherit;
		
		a {
			display: flex;
			align-items: center;
			flex-flow: row-reverse;
			justify-content: flex-end;
			
			@include mq(1023) {
				display: block;
			}
			
			.con_2col_txt_box {
				span {
					position: relative;

					&::after {
						content: "";
						display: block;
						position: absolute;
						top: 47%;
						right: -30px;
						transform: translateY(-47%);
						transition: .3s all;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 7.5px 0 7.5px 13.0px;
						border-color: transparent transparent transparent #005b97;


						@include mq(1023) {
							right: -20px;
						}

					}
				}

				
			}
			
			&:hover {
				.con_2col_txt_box {
					span {
						&::after {
							right: -35px;

							@include mq(1023) {
								right: -20px;
							}

						}
					}
				}
			}
		}
		
		&.con_2col_reverse {
			a {
				justify-content: flex-end;
				flex-flow: nowrap;
			}
		}
		
	}
	
}

/************************************************

	inquiry

*************************************************/
.inquiry_2col_box {
	max-width: 1300px;
	margin: 60px auto 0;
	display: flex;
	justify-content: space-between;
	
	@include mq(1075) {
		display: block;
		margin: 30px 10px 0 10px;
	}
	
	
	.inquiry_tell_box {
		margin-right: 15px;
		max-width: 570px;
		width: 100%;
		
		@include mq(1075) {
			max-width: inherit;
			margin-bottom: 30px;
		}
		
		h3 {
			position: relative;
			background-image: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
			background-image: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
			background-image: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
			color: #fff;
			padding: 10px 0 10px 20px;
			font-size: 2rem;
			font-weight: normal;
			
			span {
				padding-left: 105px;
				display: block;

				&::before {
					content: "";
					display: block;
					background: url("../images/ico_tell.png") no-repeat left center/43px, url("../images/ico_fax.png") no-repeat 50px center/43px;
					padding-bottom: 40px;
					position: absolute;
					left: 20px;
					top: 50%;
					transform: translateY(-50%);
					width: 95px;
				}
				
				@include mq() {
					padding-left: 54px;
					font-size: 1.7rem;
					
					&::before {
						background: url("../images/ico_tell.png") no-repeat left center/22px, url("../images/ico_fax.png") no-repeat 25px center/22px;
						left: 15px;
					}
				}
				
				
			}
			
			@include mq(1023) {
				padding: 10px 0 10px 15px;
			}
			
			
		}
		
		.inquiry_num_box {
			font-size: 2.5rem;
			/*font-size: 3.8rem;*/
			font-weight: bold;
			color: #005B97;
			padding: 20px 25px;
			line-height: 1;
			
			p {
				margin-bottom: 10px;
				
				&:last-child {
					margin-bottom: 0;
				}
			}
			
			span {
				font-size: 4rem;
				/*font-size: 2.5rem;*/
				padding-left: 20px;
			}
			
			@include mq(1075) {
				padding: 10px 12px;
			}
			
			@include mq() {
				font-size: 2rem;
				/*font-size: 3rem;*/
				
				span {
					font-size: 3rem;
					/*font-size: 2rem;*/
					padding-left: 10px;
				}
				
			}
			
			
		}
		
	}
	
	.inquiry_mail_box {
		max-width: 570px;
		width: 100%;
		
		@include mq(1075) {
			max-width: inherit;
		}
		
		h3 {
			position: relative;
			background-image: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
			background-image: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
			background-image: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
			color: #fff;
			padding: 10px 0 10px 20px;
			font-size: 2rem;
			font-weight: normal;

			span {
				padding-left: 65px;
				display: block;

				&::before {
					content: "";
					display: block;
					background: url("../images/ico_mail.png") no-repeat left center/47px;
					padding-bottom: 40px;
					position: absolute;
					left: 20px;
					top: 50%;
					transform: translateY(-50%);
					width: 47px;
				}
				
				@include mq() {
					padding-left: 33px;
					font-size: 1.7rem;
					
					&::before {
						background: url("../images/ico_mail.png") no-repeat left center/22px;
						left: 15px;
					}
					
				}
				
				
			}
			
			@include mq(1023) {
				padding: 10px 0 10px 15px;
			}
			
		}
		
		.inquiry_form_box {
			padding: 20px 25px;
			color: #005B97;
			line-height: 1;
			
			p {
				font-size: 2.1rem;
				font-weight: bold;
				margin-bottom: 10px;
				
				&:last-child {
					margin-bottom: 0;
				}
				
			}
			
			.inquiry_form_box_link {
				margin-bottom: 0;
				
				a {
					display: block;
					
					@include mq() {
						text-decoration: underline;
					}
					
					span {
						position: relative;

						&::after {
							content: "▶";
							display: block;
							position: absolute;
							top: 47%;
							right: -20px;
							transform: translateY(-47%);
							transition: .3s all;

							@include mq(1075) {
								right: -17px;
							}
							
							@include mq() {
								content: none;
							}
							
							
						}

					}
					
					&:hover {
						span {
							&::after {
								right: -25px;								
							}
						}
					}
					
				}
				
			}
			
			.inquiry_form_box_cap {
				display: inline-block;
				text-indent: -1em;
				padding-left: 15px;
				color: #333;
				line-height: 1.5em;
				margin-top: 5px;
				font-size: 1.5rem;
			}
			
			@include mq(1075) {
				padding: 10px 12px;
				
				p {
					font-size: 1.8rem;
				}
				
				.inquiry_form_box_cap {
					font-size: 1.3rem;
				}
				
				
			}
			
			@include mq(320) {
				p {
					font-size: 1.6rem;
				}
			}
			
		}
		
	}
	
}

/************************************************

	tel_link

*************************************************/
.tel_link {
	@include mq() {
		a {
			text-decoration: underline;
		}
	}
}

/************************************************

	Android css 

*************************************************/
.Android {
	.con_2col02 {
		.con_2col_txt_box {
			h3 {
				a {
					
					span {
						&::after {
							right: -30px;
						}
					}
					
				}
			}
		}
	}
	
	.inquiry_2col_box {
		.inquiry_mail_box {
			.inquiry_form_box {
				.inquiry_form_box_link {
					a {
						span {
							&::after {
								right: -25px;
							}
						}
					}
				}
			}
		}
	}
	
	.action_sub_box {
		.action_task_list {
			li {
				text-indent: -2.6em;
			}
		}
	}
	
}