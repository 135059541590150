/************************************************

	サイトポリシー

*************************************************/
.policy_box {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 10px 70px;
	
	&:last-of-type {
		padding: 0 10px;
	}
	
	@include mq() {
		padding: 0 10px 35px;
	}
	
	h3 {
		font-size: 2.5rem;
		border-bottom: 1px solid;
		padding-left: 15px;
		color: #005B97;
		margin-bottom: 37px;
		
		@include mq() {
			padding-left: 10px;
			font-size: 2.1rem;
			margin-bottom: 15px;
		}
		
	}
}

.policy_txt_box {
	margin-top: 55px;
	
	&:first-of-type {
		margin-top: 25px;
	}
	
	@include mq() {
		margin-top: 28px;
	}
	
	
	h4 {
		//border-left: 6px double #00A0E2;
		//background: #f2fbff;
		//padding: 10px 15px;
		//margin-bottom: 15px;
		position: relative;
		font-size: 1.8rem;
		padding-left: 18px;
		margin-bottom: 10px;
		
		&::before {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 7.5px 0 7.5px 13.0px;
			border-color: transparent transparent transparent #00A0E2;
			position: absolute;
			top: 7px;
			left: 0;
		}
		
	}
	
	.policy_txt_ttl02 {
		margin-bottom: 10px;
		padding: 10px 15px;
		border-left: 6px double #00A0E2;
		background: -webkit-linear-gradient(to right, #f2fbff, transparent);
		background: linear-gradient(to right, #f2fbff, transparent);
		
		&::before {
			content: none;
		}
		
	}
	
	p {
		margin-bottom: 10px;
		
		&:last-of-type {
			margin-bottom: 0;
		}
		
	}
	
	span {
		font-size: 1.4rem;
		text-indent: -1em;
		display: inline-block;
		padding-left: 14px;
	}
	
	ul {
		margin-bottom: 10px;
		
		li {
			text-indent: -0.9em;
			padding-left: 16px;
			margin-bottom: 5px;
			
			&:last-child {
				margin-bottom: 0;
			}
			
		}
	}
	
	
}

.policy_link_list {
	margin-top: 5px;
	
	dl {
		text-indent: 0.4em;
		margin-bottom: 5px;
		
		&:last-child {
			margin-bottom: 0;
		}
		
		dd {
			text-indent: -0.5em;
			padding-left: 14px;
			
			&::before {
				content: "";
				display: inline-block;
				position: relative;
				left: -5px;
				top: -3px;
				width: 7px;
				height: 7px;
				border-radius: 100%;
				background: #00A0E2;
			}
		}
		
	}
}


