/************************************************

	事業内容詳細ページ

*************************************************/
.business_tit {
	position: relative;
	background: url("../../images/business/emergency/img_tit01.jpg") no-repeat right top/cover;
	padding-bottom: 37.5%;
	margin-bottom: 90px;
	
	&.repair_tit {
		background: url("../../images/business/repair/img_tit01.jpg") no-repeat right top/cover;
	}
	
	&.snow_tit {
		background: url("../../images/business/snow/img_tit01.jpg") no-repeat right top/cover;
	}
	
	&.clean_tit {
		background: url("../../images/business/clean/img_tit01.jpg") no-repeat right top/cover;
	}
	
	&.plant_tit {
		background: url("../../images/business/plant/img_tit01.jpg") no-repeat right top/cover;
	}
	
	@include mq() {
		margin-bottom: 45px;
	}
	
	.business_tit_txt {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 6%;
		
		@include mq() {
			left: 0;
			margin-left: 10px;
		}
		
		
		h3 {
			font-size: 10rem;
			line-height: 1;
			color: #005b97;
			letter-spacing: 0.3rem;
			margin-bottom: 50px;
			
			@include mq(1023) {
				font-size: 8rem;
				margin-bottom: 30px;
			}
			
			@include mq() {
				font-size: 3.5rem;
				margin-bottom: 10px;
			}
			
			@include mq(320) {
				font-size: 3.3rem;
			}
			
			
		}
		
		p {
			font-size: 3.5rem;
			letter-spacing: 0.3rem;
			color: #4d4d4d;
			
			@include mq(1023) {
				font-size: 3.3rem;
			}
			
			@include mq() {
				font-size: 1.6rem;
			}
			
			@include mq(320) {
				font-size: 1.4rem;
			}
			
		}
		
	}
	
}

.business_detail_box {
	max-width: 1380px;
	position: relative;
	margin-bottom: 23.5%;
	
	&:last-child {
		margin-bottom: 0;
	}
	
	@include mq() {
		max-width: 100%;
		margin: 0 10px 48px;
	}
	
	.business_detail_2col {
		display: flex;
		
		@include mq() {
			margin-top: 20px;
		}
	}
	
	.business_detail_2col_cap {
		background-image: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
		background-image: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
		background-image: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
		color: #fff;
		padding: 15px 0 15px 18px;
		max-width: 900px;
		
		@include mq() {
			font-size: 1.3rem;
			padding: 10px 0 10px 14px;
			background-image: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #1e9cd7 36%, #0075be 66%, #005b97 100%);
			background-image: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #1e9cd7 36%, #0075be 66%, #005b97 100%);
			background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #1e9cd7 36%, #0075be 66%, #005b97 100%);
		}
		
		&.business_detail_2col_cap_full {
			max-width: 1200px;
		}
		
	}
	
	.business_detail_txt_box {
		-moz-box-shadow: 7px 7px 7px 0px rgba(0, 0, 0, 0.4);
		-webkit-box-shadow: 7px 7px 7px 0px rgba(0, 0, 0, 0.4);
		-ms-box-shadow: 7px 7px 7px 0px rgba(0, 0, 0, 0.4);
		box-shadow: 7px 7px 7px 0px rgba(0, 0, 0, 0.4);
		padding: 50px 35px;
		background: #fff;
		max-width: 38%;
		position: absolute;
		bottom: -14%;
		right: 1%;
		
		&.business_repair_txt_box {
			bottom: -30%;
		}
		
		&.business_plant_txt_box {
			bottom: -30%;
		}
		
		@include mq() {
			box-shadow: none;
			position: static;
			padding: 0;
			max-width: 100%;
		}
		
		
		h3 {
			border: 1px solid #005b97;
			line-height: 1;
			text-align: center;
			color: #005b97;
			font-size: 3rem;
			padding: 15px 0;
			margin-bottom: 20px;
			letter-spacing: 0.3rem;
			
			@include mq() {
				font-size: 2.1rem;
				padding: 10px 0;
			}
			
		}
		
		p {
			font-size: 1.8rem;
			color: #4d4d4d;
			
			@include mq() {
				font-size: 1.6rem;
			}
			
		}
		
	}
	
	&.business_detail_box_reverse {
		margin-left: auto;		
		
		&:last-child {
			margin-bottom: 0;
		}
		
		@include mq() {
			max-width: 100%;
			margin: 0 10px 48px;
		}
		
		.business_detail_2col {
			justify-content: flex-end;
		}
		
		.business_detail_2col_cap {
			margin-left: auto;
			text-align: right;
			padding: 15px 18px 15px 0;
			background-image: -moz-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
			background-image: -webkit-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
			background-image: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(30,156,215,1) 56%, rgba(0,117,190,1) 86%, rgba(0,91,151,1) 100%);
			
			@include mq() {
				text-align: left;
				background-image: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #1e9cd7 36%, #0075be 66%, #005b97 100%);
				background-image: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #1e9cd7 36%, #0075be 66%, #005b97 100%);
				background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #1e9cd7 36%, #0075be 66%, #005b97 100%);
				padding: 10px 0 10px 14px;
			}
			
		}
		
		.business_detail_txt_box {
			left: 1%;
		}
		
	}
	
	&.business_detail_box_solo {
		.business_detail_2col {
			p {
				@include mq() {
					width: 100%;
				}
			}
		}
		
		.business_detail_2col_cap {
			max-width: 582px;
			
			@include mq() {
				max-width: 900px;
			}
			
		}
		
	}
	
}






