/************************************************

	事業内容

*************************************************/
.business_top_txt01 {
	text-align: center;
	color: #4d4d4d;
	margin-bottom: 135px;
	
	@include mq(1023) {
		margin: 0 10px 50px;
	}
	
	h3 {
		font-size: 4.2rem;
		letter-spacing: 0.3rem;
		margin-bottom: 30px;
		
		@include mq(1023) {
			font-size: 2.2rem;
			margin-bottom: 20px;
		}
		
		@include mq(320) {
			font-size: 2.1rem;
		}
		
	}
	
	p {
		font-size: 2.5rem;
		font-weight: normal;
		
		@include mq(1023) {
			font-size: 1.8rem;
		}
		
		@include mq(320) {
			font-size: 1.7rem;
		}
		
	}
	
}

.business_top_menu {
	max-width: 1210px;
    margin: 0 auto;
	padding-bottom: 250px;
	
	@include mq(1023) {
		padding-bottom: 125px;
	}
	
	@include mq() {
		display: none;
	}
	
	.business_top_menu_link {
		area {
			transition: .3s;
			
			&:hover {
				opacity: .7;
			}
			
			&:focus {
				outline: none;
			}
		}
		
	}
	
}

.business_top_sp_menu {
	display: none;
	
	@include mq() {
		display: block;
		margin: 0 10px;
		padding-bottom: 60px;
		
		ul {
			li {
				margin-bottom: 20px;
				
				&:last-child {
					margin-bottom: 0;
				}
				
			}
		}
		
	}
	
}

.business_top_point {
	
	@include mq(1023) {
		margin: 0 10px;
	}
	
	
	.product_btn_more {
		margin: 70px 0 0 30px;
		text-align: center;

		@include mq(1023) {
			margin: 15px auto 0;
			text-align: left;
		}

		a {
			display: inline-block;
			border: 1px solid $blue01;
			color: $blue01;
			padding: 10px 15px;
			text-align: center;
			font-size: 2.3rem;
			font-weight: bold;
			position: relative;
			overflow: hidden;
			transition: color .3s, background .3s;
			box-sizing: border-box;
			background: #fff;

			@include mq(1023) {
				font-size: 1.6rem;
				max-width: 250px;
				margin: 0 auto;
			}

			@include pc_hover {
				color: #fff;
				//background: $blue01;

				&::before {
					transform: scaleY(1);
				}

				.ico_product .ico .cls-1 {
					fill: #fff;
				}

				.ico_product .ico .cls-2 {
					fill: #fff;
					stroke: $blue01;
				}

				.ico_arrow01::after {
					border-color: transparent transparent transparent #fff;
				}
			}

			&::before {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				background: $blue01;
				position: absolute;
				left: 0;
				bottom: 0;
				transition: transform .3s;
				transform-origin: bottom;
				//transform: rotateX(90deg);
				transform: scaleY(0);
			}

		}

		.ico_product {
			position: relative;
			display: block;
			min-height: 25px;
			line-height: 25px;
			padding-left: 50px;

			@include mq() {
				min-height: 21px;
				line-height: 21px;
				padding-left: 35px;
			}

			.ico {
				display: block;
				width: 43px;
				height: 25px;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				font-size: 0;

				@include mq() {
					width: round(43px * .8);
					height: round(25px * .8);
				}

				.cls-1{
					fill: $blue01;
					transition: fill .3s;
				}

				.cls-2{
					fill: $blue01;
					transition: fill .3s, stroke .3s;
					stroke: #fff;
					stroke-miterlimit: 10;
					stroke-width: 0.74px;
				}
			}
		}

		.ico_arrow01 {
			position: relative;
			display: block;
			padding-left: 10px;
			padding-right: 27px;

			&::after {
				content: '';
				display: block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 12px 0 12px 12px;
				border-color: transparent transparent transparent $blue01;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				transition: border-color .3s;

				@include mq() {
					border-width: 8px 0 8px 8px;
				}
			}
		}
	}
}

.business_applicant__btn {
	max-width: 450px;
	margin: 15px auto 0;
	
	@include mq() {
		max-width: 260px;
		margin: 15px 0 0;
	}
	
	a {
		display: block;
		background: $blue02;
		color: #fff;
		font-size: 2.3rem;
		font-weight: bold;
		text-align: center;
		box-sizing: border-box;
		padding: 15px;
		transition: .3s opacity;
		
		@include pc_hover {
			opacity: .7;
		}
		
		@include mq() {
			font-size: 1.6rem;
			padding: 10px;
		}
		
	}
	
	span {
		position: relative;
		padding-right: 35px;
		
		@include mq() {
			padding-right: 25px;
		}
		
		&::after {
			content: "";
			display: block;
			background: url("../images/ico_arrow07.svg") no-repeat right center / 25px;
			width: 25px;
			height: 26px;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			
			@include mq() {
				background-size: 19px;
				width: 19px;
				height: 20px;
			}
			
		}
		
	}
	
}

.business_top_bnr {
	margin: 35px auto 0;
    padding: 0 20px;
    text-align: center;
    max-width: 1100px;
	
	@include mq() {
		margin: 35px 10px 0;
	}

	a {
		transition: opacity .3s;

		@include pc_hover {
			opacity: .7;
		}
	}
	
}

